import React, { Component } from 'react';
import axios from "axios"
import AddTitleHelmet from "../Helmet"
import BackButton from "../UI/BackBtn"
import { userEmail } from "../../utils/helpers"
import FormLoader from "../loader/FormLoader"
import apis from "../../utils/api"
import { updateDropOff } from "../../utils/calls/dropOff.calls"

const { VERIFICATION_API } = apis
const baseUrl = VERIFICATION_API
export default class Risks extends Component {
  state = {
    hasError: false,
    acceptNoRefundOnFailure: false,
    earlyNoDividendPaymentAndProfit: false,
    earlyNoDividendPaymentAndProfit2: false,
    earlyNoDividendPaymentAndProfit3: false,
    earlyNoDividendPaymentAndProfit4: false,
    dropOffData: [],
    dropOffLoading: false,
  }

  constructor(props) {
    super(props)
    this.acceptNoRefundOnFailureRef = React.createRef()
    this.earlyNoDividendPaymentAndProfitRef = React.createRef()
    this.earlyNoDividendPaymentAndProfit2Ref = React.createRef()
    this.earlyNoDividendPaymentAndProfit3Ref = React.createRef()
    this.earlyNoDividendPaymentAndProfit4Ref = React.createRef()
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  completeFormValidation = () => {
    const email = userEmail()
    let {
      acceptNoRefundOnFailure,
      earlyNoDividendPaymentAndProfit,
      earlyNoDividendPaymentAndProfit2,
      earlyNoDividendPaymentAndProfit3,
      earlyNoDividendPaymentAndProfit4,
    } = this.state

    if (
      !acceptNoRefundOnFailure ||
      !earlyNoDividendPaymentAndProfit ||
      !earlyNoDividendPaymentAndProfit2 ||
      !earlyNoDividendPaymentAndProfit3 ||
      !earlyNoDividendPaymentAndProfit4
    ) {
      this.setState({ hasError: true })
      return
    }

    let updatedDropOffData = {
      riskwarnings1: acceptNoRefundOnFailure,
      riskwarnings2: earlyNoDividendPaymentAndProfit,
      riskwarnings3: earlyNoDividendPaymentAndProfit2,
      riskwarnings4: earlyNoDividendPaymentAndProfit3,
      riskwarnings5: earlyNoDividendPaymentAndProfit4,
      email: email,
      pageno: this.props.currentPage + 1,
    }

    this.props.setPage(this.props.currentPage + 1)
    updateDropOff(updatedDropOffData)
  }

  setInitialStates = () => {
    if (!this.state.dropOffLoading && this.state.dropOffData.riskwarnings1) {
      this.setState({
        acceptNoRefundOnFailure: true,
      })
      this.acceptNoRefundOnFailureRef.current.checked = true
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.riskwarnings2) {
      this.setState({
        earlyNoDividendPaymentAndProfit: true,
      })
      this.earlyNoDividendPaymentAndProfitRef.current.checked = true
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.riskwarnings3) {
      this.setState({
        earlyNoDividendPaymentAndProfit2: true,
      })
      this.earlyNoDividendPaymentAndProfit2Ref.current.checked = true
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.riskwarnings4) {
      this.setState({
        earlyNoDividendPaymentAndProfit3: true,
      })
      this.earlyNoDividendPaymentAndProfit3Ref.current.checked = true
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.riskwarnings5) {
      this.setState({
        earlyNoDividendPaymentAndProfit4: true,
      })
      this.earlyNoDividendPaymentAndProfit4Ref.current.checked = true
    }
  }

  componentDidMount() {
    const getDropOff = async () => {
      const email = userEmail()
      this.setState({ dropOffLoading: true })

      await axios
        .get(`${baseUrl}/v1/dropOff/get/${email}`)
        .then(res => {
          this.setState({ dropOffData: res.data, dropOffLoading: false })
        })
        .catch(error => {
          this.setState({ dropOffLoading: false })
          console.dir(error)
        })

      this.setInitialStates()
    }
    getDropOff()
  }

  render() {
    return (
      <React.Fragment>
        <AddTitleHelmet title='Syndicate KYC | Risks' />
        {this.state.dropOffLoading && (
          <FormLoader style={{ backgroundColor: "hsla(0, 50%, 100%, 0.5)" }} />
        )}
        <div className='col-sm-6 col-xs-12'>
          <div className='basic-info'>
            <div className='title-header'>
              <span className='sub-title'>
                Please tick the boxes to confirm your agreement of the following
              </span>
            </div>

            <div
              className={
                "checkbox-long" +
                (this.state.hasError && !this.state.acceptNoRefundOnFailure
                  ? " has-error"
                  : "")
              }
            >
              <label htmlFor=''>
                <input
                  type='checkbox'
                  name='acceptNoRefundOnFailure'
                  onChange={this.onChange}
                  ref={this.acceptNoRefundOnFailureRef}
                />
                I understand that startup investing is very risky and startups
                can fail at any time. If a startup fails neither the company nor
                the Syndicate is obliged to refund my investment.
              </label>
            </div>

            <div
              className={
                "checkbox-long" +
                (this.state.hasError &&
                !this.state.earlyNoDividendPaymentAndProfit
                  ? " has-error"
                  : "")
              }
            >
              <label htmlFor=''>
                <input
                  type='checkbox'
                  name='earlyNoDividendPaymentAndProfit'
                  onChange={this.onChange}
                  ref={this.earlyNoDividendPaymentAndProfitRef}
                />
                I understand the early-stage company most often will not pay
                dividends and profits made in any year are reinvested into the
                business to sustain its growth and increase its equity value.
              </label>
            </div>

            <div
              className={
                "checkbox-long" +
                (this.state.hasError &&
                !this.state.earlyNoDividendPaymentAndProfit2
                  ? " has-error"
                  : "")
              }
            >
              <label htmlFor=''>
                <input
                  type='checkbox'
                  name='earlyNoDividendPaymentAndProfit2'
                  onChange={this.onChange}
                  ref={this.earlyNoDividendPaymentAndProfit2Ref}
                />
                I understand that it is necessary to spread my risk across
                several investments and diversify my portfolio.
              </label>
            </div>

            <div
              className={
                "checkbox-long" +
                (this.state.hasError &&
                !this.state.earlyNoDividendPaymentAndProfit3
                  ? " has-error"
                  : "")
              }
            >
              <label htmlFor=''>
                <input
                  type='checkbox'
                  name='earlyNoDividendPaymentAndProfit3'
                  onChange={this.onChange}
                  ref={this.earlyNoDividendPaymentAndProfit3Ref}
                />
                I understand that neither I, the company nor the syndicate can
                sell my shares unless the company procures an exit event in the
                future or the company is acquired.
              </label>
            </div>

            <div
              className={
                "checkbox-long" +
                (this.state.hasError &&
                !this.state.earlyNoDividendPaymentAndProfit4
                  ? " has-error"
                  : "")
              }
            >
              <label htmlFor=''>
                <input
                  type='checkbox'
                  name='earlyNoDividendPaymentAndProfit4'
                  onChange={this.onChange}
                  ref={this.earlyNoDividendPaymentAndProfit4Ref}
                />
                I understand that I will get information about the company from
                time to time and it may not often times suffice but I am welcome
                to request additional information about the company.
              </label>
            </div>
          </div>

          <div className='pagination'>
            <BackButton
              currentPage={this.props.currentPage}
              nextPage={this.props.setPage}
            />
            <div>
              {" "}
              <div
                className='link'
                onClick={e => this.completeFormValidation()}
              >
                Next
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}