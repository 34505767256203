import React, { Component } from "react";
import axios from "axios";
// import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../Header";
import Accreditation from "./Accreditation";
import Complete from "./Complete";
import Identification from "./Identification";
import InvestmentAccount from "./InvestmentAccount";
// import KYCPayment from "./KYCPayment";
import KYCPay from "./KYCPay"
import Risks from "./Risks"
import Upload from "./Upload"
import Loader from "../loader/Loader"
import apis from "../../utils/api"
import MobileSteps from "./MobileSteps"

const { UNIVERSAL_API } = apis
// const { VERIFICATION_API, UNIVERSAL_API } = apis
// const baseUrl = VERIFICATION_API

const API_TOKEN =
  "FDshA17s4G79Ga7wBDOAgAhkTI1mJ9z8U5oGOAg8JGR48tJX6QTDnk8EWQbB9mzKe2s"
const UNIVERSAL_API_EMAIL = "dev@cchubnigeria.com"

class Form extends Component {
  state = {
    currentPage: -1,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    country: "",
    city: "",
    zip_code: "",
    planToInvest: "",
    investmentsPerYear: "",
    howManyInvestmentsBool: "",
    howWillYouInvest: "",
    investmentSectors: "",
    investmentStage: "",
    canEvaluateRisk: "",
    experienceInvesting: "",
    startupInvestedIn: "",
    annualIncome: "",
    totalNetAssets: "",
    uploadDocuments: [],
    uploadedPassport: "",
    whatHappensToStartups: "",
    getMyMoneyBack: "",
    iInvestAndItFails: "",
    regularDividendPayments: "",
    howLongBeforeReturns: "",
    ifISellMyShares: "",
    accreditated: false,
    shouldIDiversifyInvestment: "",
    authUserId: "",
    accuantId: "",
    acceptNoRefundOnFailure: "",
    earlyNoDividendPaymentAndProfit: "",
    earlyNoDividendPaymentAndProfit2: "",
    earlyNoDividendPaymentAndProfit3: "",
    earlyNoDividendPaymentAndProfit4: "",
    accreditationHasError: false,
    identificationHasError: false,
    investmentHasError: false,
    riskHasError: false,
    uploadHasError: false,
    kycPaymentHasError: false,
    identificationSubmitted: false,
    isLoading: false,
    selectedCities: [],
    docCountry: "",
    scanData: "",
    additionalFunds: "",
  }

  componentDidMount() {
    const auth = localStorage.getItem("auth")
    const pageNoFromStore = !localStorage.getItem("pageNo")
      ? this.state.currentPage
      : Number(localStorage.getItem("pageNo"))

    if (!auth) {
      this.props.history.push("/email-verification")
      return
    }

    const authObject = JSON.parse(auth)
    this.setState({ email: authObject.userData["email"] })
    this.setState({ currentPage: pageNoFromStore })

    if (this.state.currentPage === 5) {
      //localStorage.removeItem('auth');
      return
    }
  }

  getCountryCities = country => {
    const config = {
      headers: {
        Accept: "application/json",
        "api-token": API_TOKEN,
        "user-email": UNIVERSAL_API_EMAIL,
      },
    }
    axios
      .get(`${UNIVERSAL_API}/api/getaccesstoken`, config)
      .then(resp => {
        const authToken = resp.data.auth_token

        const authConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
        axios
          .get(`${UNIVERSAL_API}/api/states/${country}`, authConfig)
          .then(({ data }) => {
            this.setState({ selectedCities: data, isLoading: false })
          })
          .catch(error => {
            console.dir(error)
            this.setState({ isLoading: false })
          })
      })
      .catch(error => {
        console.error(error)
        this.setState({ isLoading: false })
      })
  }

  setPage = page => {
    this.setState({ currentPage: page })
  }

  setData = data => {
    this.setState(data)
  }

  setPhone = number => {
    this.setState({ phone: number })
  }

  nextPage = currentPage => {
    this.setState({ currentPage })
  }

  onHandleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onCountryChange = e => {
    this.setState({ country: e.target.value })
    this.getCountryCities(e.target.value)
  }

  uploadDocument = value => {
    this.setState({ uploadDocument: value })
  }

  showToast = (status, msg) => {
    if (status === "success") {
      return toast.success(msg)
    }
    return toast.error(msg)
  }

  accreditate = e => {
    console.dir(this.state)
    let {
      whatHappensToStartups,
      getMyMoneyBack,
      iInvestAndItFails,
      regularDividendPayments,
      howLongBeforeReturns,
      ifISellMyShares,
      additionalFunds,
    } = this.state
    if (
      whatHappensToStartups === "Fail" &&
      getMyMoneyBack === "No, I will not" &&
      iInvestAndItFails === "unlikely" &&
      regularDividendPayments === "yes" &&
      howLongBeforeReturns === "4-7 years" &&
      ifISellMyShares === "wait" &&
      additionalFunds === "decrease"
    ) {
      this.setState({ accreditated: true })
      this.setPage(this.state.currentPage + 1)
      return
    } else {
      this.setState({ accreditated: false })
      this.showToast(
        "error",
        "You didn't pass the quiz due to not understanding the risks involved in investing in startups."
      )
      return
    }
  }

  // completeApplication = () => {
  //   let {
  //     planToInvest,
  //     investmentStage,
  //     investmentSectors,
  //     investmentsPerYear,
  //     howWillYouInvest,
  //     canEvaluateRisk,
  //     experienceInvesting,
  //     startupInvestedIn,
  //     annualIncome,
  //     totalNetAssets,
  //     authUserId,
  //   } = this.state

  //   let payload = {
  //     amountToInvest: planToInvest,
  //     investmentPerYear: investmentsPerYear,
  //     experienceInvesting,
  //     startupInvestedIn,
  //     methodOfInvestment: howWillYouInvest,
  //     sector: investmentSectors,
  //     riskEvaluationAbility: canEvaluateRisk,
  //     annualIncomeInUSD: annualIncome,
  //     totalNetAssets,
  //     investmentStage,
  //   }
  //   this.setState({ isLoading: true })
  //   axios
  //     .put(`${VERIFICATION_API}/edit/update/customer/${authUserId}`, payload)
  //     .then(({ data }) => {
  //       if (data.message === "Application updated") {
  //         this.setState({ isLoading: false })
  //         return
  //       }
  //     })
  //     .catch(error => {
  //       this.showToast("error", "An Error occurred")
  //       this.setState({ isLoading: false })
  //     })
  // }

  updateInvestmentInformation = () => {
    let {
      planToInvest,
      investmentStage,
      investmentSectors,
      investmentsPerYear,
      howWillYouInvest,
      canEvaluateRisk,
      experienceInvesting,
      annualIncome,
      totalNetAssets,
    } = this.state

    if (
      !planToInvest ||
      !investmentStage ||
      !investmentSectors ||
      !investmentsPerYear ||
      !howWillYouInvest ||
      !canEvaluateRisk ||
      !experienceInvesting ||
      !annualIncome ||
      !totalNetAssets
    ) {
      this.setState({ investmentHasError: true })
      return
    }
    this.setPage(2)
  }

  setInvestmentSectors = sectors => {
    this.setState({ investmentSectors: sectors })
  }

  // customerApplicationDetails = () => {
  //   let {
  //     firstName,
  //     lastName,
  //     email,
  //     phone,
  //     address,
  //     country,
  //     city,
  //     zip_code,
  //     planToInvest,
  //     investmentStage,
  //     investmentSectors,
  //     investmentsPerYear,
  //     howWillYouInvest,
  //     canEvaluateRisk,
  //     experienceInvesting,
  //     startupInvestedIn,
  //     annualIncome,
  //     totalNetAssets,
  //     docCountry,
  //     scanData,
  //   } = this.state

  //   let payload = {
  //     firstName,
  //     lastName,
  //     email,
  //     mobile: phone,
  //     streetAddress: address,
  //     country,
  //     city,
  //     zipCode: zip_code,
  //     amountToInvest: planToInvest,
  //     investmentPerYear: investmentsPerYear,
  //     experienceInvesting,
  //     startupInvestedIn,
  //     methodOfInvestment: howWillYouInvest,
  //     sector: investmentSectors,
  //     riskEvaluationAbility: canEvaluateRisk,
  //     annualIncomeInUSD: annualIncome,
  //     totalNetAssets,
  //     investmentStage,
  //     scanData,
  //     docCountry,
  //   }
  //   console.dir(payload)

  //   this.setState({ identificationSubmitted: false })
  //   if (
  //     !firstName ||
  //     !lastName ||
  //     !email ||
  //     !phone ||
  //     !address ||
  //     !country ||
  //     !city ||
  //     !zip_code ||
  //     !planToInvest ||
  //     !investmentStage ||
  //     !investmentSectors ||
  //     !investmentsPerYear ||
  //     !howWillYouInvest ||
  //     !canEvaluateRisk ||
  //     !experienceInvesting ||
  //     !annualIncome ||
  //     !totalNetAssets
  //   ) {
  //     this.setState({ identificationHasError: true })
  //     return
  //   }

  //   this.setState({ isLoading: true })
  //   axios
  //     .post(`${baseUrl}/v1/customer/application`, payload)
  //     .then(response => {
  //       if (response.status && _.inRange(response.status, 400, 500)) {
  //         this.showToast("error", "An error occurred")
  //         this.setState({ isLoading: false })
  //         return
  //       }
  //       this.setState({ isLoading: false })
  //     })
  //     .catch(error => {
  //       this.setState({ isLoading: false })
  //       return
  //     })
  // }

  completeForm = () => {
    let {
      acceptNoRefundOnFailure,
      earlyNoDividendPaymentAndProfit,
      earlyNoDividendPaymentAndProfit2,
      earlyNoDividendPaymentAndProfit3,
      earlyNoDividendPaymentAndProfit4,
    } = this.state

    if (
      !acceptNoRefundOnFailure ||
      !earlyNoDividendPaymentAndProfit2 ||
      !earlyNoDividendPaymentAndProfit ||
      !earlyNoDividendPaymentAndProfit3 ||
      !earlyNoDividendPaymentAndProfit4
    ) {
      this.setState({ riskHasError: true })
      return
    }
    this.setPage(3)
  }

  render() {
    // console.log(this.state, "main stateeee")

    let {
      currentPage,
      firstName,
      lastName,
      email,
      country,
      city,
      phone,
      address,
      zip_code,
      planToInvest,
      investmentSectors,
      investmentStage,
      investmentsPerYear,
      howManyInvestmentsBool,
      howWillYouInvest,
      canEvaluateRisk,
      experienceInvesting,
      startupInvestedIn,
      annualIncome,
      totalNetAssets,
      whatHappensToStartups,
      getMyMoneyBack,
      iInvestAndItFails,
      regularDividendPayments,
      howLongBeforeReturns,
      ifISellMyShares,
      shouldIDiversifyInvestment,
      accreditated,
      uploadDocuments,
      authUserId,
      accuantId,
      selectedCities,
      accreditationHasError,
      identificationHasError,
      investmentHasError,
      uploadHasError,
      identificationSubmitted,
      isLoading,
    } = this.state

    return (
      <React.Fragment>
        <Header />
        <div id='step-1'>
          <div className=''>
            <div className='row'>
              <div className='col-sm-4 col-xs-12'>
                <div className='phases'>
                  <ul>
                    <li className='active-tab'>
                      Accreditation Questionnaire{" "}
                      {currentPage > -1 ? (
                        <img src='/svg/green-tick.svg' alt='' />
                      ) : (
                        <img src='/svg/black-circle.svg' alt='' />
                      )}{" "}
                    </li>
                    <li>
                      Identification{" "}
                      {currentPage > 0 ? (
                        <img src='/svg/green-tick.svg' alt='' />
                      ) : (
                        <img src='/svg/black-circle.svg' alt='' />
                      )}
                    </li>
                    <li>
                      Investment Activities{" "}
                      {currentPage > 1 ? (
                        <img src='/svg/green-tick.svg' alt='' />
                      ) : (
                        <img src='/svg/black-circle.svg' alt='' />
                      )}
                    </li>
                    <li>
                      Risk Warnings, Information & Consent{" "}
                      {currentPage > 2 ? (
                        <img src='/svg/green-tick.svg' alt='' />
                      ) : (
                        <img src='/svg/black-circle.svg' alt='' />
                      )}
                    </li>
                    <li>
                      Upload Identification
                      {currentPage > 3 ? (
                        <img src='/svg/green-tick.svg' alt='' />
                      ) : (
                        <img src='/svg/black-circle.svg' alt='' />
                      )}
                    </li>
                    <li>
                      Admin Fees
                      {currentPage > 4 ? (
                        <img src='/svg/green-tick.svg' alt='' />
                      ) : (
                        <img src='/svg/black-circle.svg' alt='' />
                      )}
                    </li>
                  </ul>
                </div>

                <MobileSteps currentPage={currentPage} />
              </div>
              {/**  */}
              {this.state.isLoading && <Loader />}
              {currentPage === -1 && (
                <Accreditation
                  currentPage={currentPage}
                  setPage={this.setPage}
                  whatHappensToStartups={whatHappensToStartups}
                  getMyMoneyBack={getMyMoneyBack}
                  iInvestAndItFails={iInvestAndItFails}
                  regularDividendPayments={regularDividendPayments}
                  howLongBeforeReturns={howLongBeforeReturns}
                  ifISellMyShares={ifISellMyShares}
                  shouldIDiversifyInvestment={shouldIDiversifyInvestment}
                  accreditate={this.accreditate}
                  accreditated={accreditated}
                  hasError={accreditationHasError}
                  onHandleChange={this.onHandleChange}
                />
              )}
              {currentPage === 0 && (
                <Identification
                  currentPage={currentPage}
                  setPage={this.setPage}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phone={phone}
                  setPhone={this.setPhone}
                  address={address}
                  country={country}
                  city={city}
                  zip_code={zip_code}
                  authUserId={authUserId}
                  selectedCities={selectedCities}
                  uploadDocuments={uploadDocuments}
                  hasError={identificationHasError}
                  formSubmitted={identificationSubmitted}
                  uploadDocs={this.uploadDocument}
                  onHandleChange={this.onHandleChange}
                  onCountryChange={this.onCountryChange}
                  nextPage={this.nextPage}
                  customerApplicationDetails={this.customerApplicationDetails}
                />
              )}

              {currentPage === 1 && (
                <InvestmentAccount
                  setPage={this.setPage}
                  currentPage={this.state.currentPage}
                  planToInvest={planToInvest}
                  investmentStage={investmentStage}
                  investmentSectors={investmentSectors}
                  investmentsPerYear={investmentsPerYear}
                  howManyInvestmentsBool={howManyInvestmentsBool}
                  howWillYouInvest={howWillYouInvest}
                  canEvaluateRisk={canEvaluateRisk}
                  experienceInvesting={experienceInvesting}
                  startupInvestedIn={startupInvestedIn}
                  annualIncome={annualIncome}
                  totalNetAssets={totalNetAssets}
                  hasError={investmentHasError}
                  setItem={(e, t) => this.setState({ [e]: t })}
                  onHandleChange={this.onHandleChange}
                  updateInvestmentInformation={this.updateInvestmentInformation}
                  setInvestmentSectors={this.setInvestmentSectors}
                />
              )}

              {currentPage === 2 && (
                <Risks
                  currentPage={currentPage}
                  onHandleChange={this.onHandleChange}
                  setPage={this.setPage}
                  completeForm={this.completeForm}
                />
              )}

              {currentPage === 4 && (
                <KYCPay
                  currentPage={currentPage}
                  email={email}
                  completeApplication={this.customerApplicationDetails}
                  setPage={this.setPage}
                  checkCreds={this.state}
                />
              )}

              {currentPage === 3 && (
                <Upload
                  setPage={this.setPage}
                  currentPage={currentPage}
                  docCountry={this.state.docCountry}
                  scanData={this.state.scanData}
                  authUserId={authUserId}
                  accuantId={accuantId}
                  hasError={uploadHasError}
                  setData={this.setData}
                  country={country}
                  onHandleChange={this.onHandleChange}
                />
              )}

              {currentPage === 5 && (
                <Complete
                  isLoading={isLoading}
                  completeApplication={this.customerApplicationDetails}
                  setPage={this.setPage}
                />
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    )
  }
}

export default Form;
