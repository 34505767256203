import React, { Component } from 'react'
import { ToastContainer, toast } from "react-toastify"
import completeSVG from "../../assets/svg/complete.svg"
import AddTitleHelmet from "../Helmet"
import Loader from "../loader/Loader"

class Complete extends Component {
  state = {
    isLoading: false,
  }

  componentDidMount() {
    localStorage.clear()
    toast.info(`This Window will close soon`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    setTimeout(() => {
      window.close()
    }, 11000)
  }

  render() {
    return (
      <React.Fragment>
        <AddTitleHelmet title='Syndicate KYC | Application Complete' />

        <div className='col-sm-5 col-xs-12'>
          <div className='basic-info'>
            <div className='success-center'>
              <img src={completeSVG} alt='' />
              <h4>Successful Accreditation</h4>

              <p
                style={{
                  fontSize: "11px",
                  textAlign: "center",
                  marginTop: "21px",
                }}
              >
                Thank you for completing this application as part of the
                accreditation process for The CcHUB Syndicate.
              </p>
            </div>
          </div>
          {this.props.isLoading && <Loader />}
        </div>
        <ToastContainer />
      </React.Fragment>
    )
  }
}

export default Complete