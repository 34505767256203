import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
// import Rave, { VerifyTransaction } from "react-flutterwave-rave"
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { ToastContainer, toast } from "react-toastify";

import AddTitleHelmet from "../Helmet";
import BackButton from "../UI/BackBtn";
import FormLoader from "../loader/FormLoader";
import apis from "../../utils/api";
import { updateDropOff } from "../../utils/calls/dropOff.calls";
import { userEmail } from "../../utils/helpers";
import { getExchangeRate } from "../../utils/calls/foreignExchange";

const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";
const { VERIFICATION_API } = apis;
const baseUrl = VERIFICATION_API;

const KYCPay = (props) => {
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  //   const [email, setEmail] = useState("")
  const [amount, setAmount] = useState("");
  const [userLinkedIn, setUserLinkedIn] = useState("");
  const [dropOffData, setDropOffData] = useState({});
  const [dropOffLoading, setDropOffLoading] = useState(false);

  const showToast = (status, msg) => {
    if (status === "success") {
      return toast.success(msg);
    }
    return toast.error(msg);
  };

  const customerApplicationDetails = () => {
    let {
      first_name,
      last_name,
      email,
      mobile,
      address,
      country,
      city,
      zipcode,
      amounttoinvest,
      stages,
      sectors,
      investmentsperyear,
      typeofinvestor,
      riskevaluation,
      experience,
      startupsinvestedin,
      annualincome,
      totalnetassets,
      nationality,
      uploaded_id,
    } = dropOffData;

    let payload = {
      firstName: first_name,
      lastName: last_name,
      email,
      mobile,
      streetAddress: address,
      country,
      city,
      zipCode: zipcode,
      amountToInvest: amounttoinvest,
      investmentPerYear: investmentsperyear,
      experienceInvesting: experience,
      startupInvestedIn: startupsinvestedin,
      methodOfInvestment: typeofinvestor,
      sector: sectors,
      riskEvaluationAbility: riskevaluation,
      annualIncomeInUSD: annualincome,
      totalNetAssets: totalnetassets,
      investmentStage: stages,
      scanData: uploaded_id,
      docCountry: nationality,
    };

    axios
      .post(`${baseUrl}/v1/customer/application`, payload)
      .then((response) => {
        // console.log(response, "res from cust applic")
        if (response.status && _.inRange(response.status, 400, 500)) {
          showToast("error", "An error occurred");
          // this.setState({ isLoading: false })
          return;
        }
        // this.setState({ isLoading: false })
      })
      .catch((error) => {
        console.log(error);
        // this.setState({ isLoading: false })
        return;
      });
  };

  const config = {
    public_key: process.env.REACT_APP_FW_PUBLIC,
    tx_ref: Date.now(),
    amount: amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    // redirect_url: "http://localhost:3000/form",
    customer: {
      email: props.email,
      name: `${dropOffData.first_name} ${dropOffData.last_name}`,
      phonenumber: `${dropOffData.mobile}`,
    },
    customizations: {
      title: "KYC Verification Fees",
      description: "KYC verification fees",
      logo: "https://cchubnigeria.com/syndicate/assets/svg/syndicate-logo.svg",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    const getDropOff = async () => {
      const email = userEmail();
      setDropOffLoading(true);

      setTimeout(() => {
        axios
          .get(`${baseUrl}/v1/dropOff/get/${email}`)
          .then((res) => {
            setDropOffData(res.data);
            setDropOffLoading(false);
          })
          .catch((error) => {
            setDropOffLoading(false);
            console.dir(error);
          });
      }, 1000);
    };

    getDropOff();
    const userData = JSON.parse(localStorage.getItem("auth"));
    userData.userData.linkedin && setUserLinkedIn(userData.userData.linkedin);
    isProduction
      ? getExchangeRate().then((result) =>
          setAmount(300 * result.data.exchange_rate)
        )
      : setAmount("2000");
  }, []);

  return (
    <React.Fragment>
      <AddTitleHelmet title="Syndicate KYC | Admin Fees" />
      {dropOffLoading && (
        <FormLoader style={{ backgroundColor: "hsla(0, 50%, 100%, 0.5)" }} />
      )}
      <div className="col-sm-4 col-xs-12">
        <div className="basic-info">
          <div className="title-header">
            <h1 className="hide-on-mobile">Admin fees</h1>
            <br className="hide-on-mobile" />
            <span>
              We are required to run a KYC check on all of our investors hence
              you will need to pay a non-refundable fee of{" "}
              <i style={{ fontWeight: "bold" }}>$300 (N{amount}) </i> and pass
              the process to be able to access deals and invest in start-ups
              through the syndicate.
            </span>
          </div>
          <div className="payment-form">
            <label htmlFor="">Amount (NGN)</label>
            <input
              className="form-control syndicate-control"
              type="text"
              placeholder="300"
              value={amount}
              readOnly
              style={{ width: "200px" }}
            />
          </div>
        </div>

        <div className="pagination">
          <BackButton
            currentPage={props.currentPage}
            nextPage={props.setPage}
          />
          <div>
            <button
              className="link"
              onClick={() => {
                handleFlutterPayment({
                  callback: async (response) => {
                    // console.log(response, "from callback")
                    if (response.status === "successful") {
                      setIsPaymentSuccessful(true);
                      customerApplicationDetails();
                      updateDropOff({
                        email: userEmail(),
                        pageno: props.currentPage + 1,
                        paid_fees: true,
                        completed: true,
                        linkedin: userLinkedIn,
                      });
                      props.setPage(props.currentPage + 1);
                      setTimeout(() => closePaymentModal(), 2000);
                      // this will close the modal programmatically
                    } else {
                      alert("Transaction was not successful. Try again later");
                      return;
                    }
                  },
                  onClose: () => {
                    if (isPaymentSuccessful) {
                      console.log("payment success...");
                    }
                  },
                });
              }}
            >
              Pay Fees
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};
export default KYCPay;
