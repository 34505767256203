import React from 'react'
import { Helmet } from 'react-helmet'

const AddTitleHelmet = ({ title }) => {
    return (
        <Helmet>
            <title>{title ? title: 'Syndicate KYC'}</title>
        </Helmet>
    )
}
export default AddTitleHelmet