import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Fonts from "./Fonts";

import { Provider } from "react-redux";
import { store } from "./Redux/store";

import "./App.css";
import "./upload.css";

import Identification from "./components/Onboarding/Identification";
import InvestmentAccount from "./components/Onboarding/InvestmentAccount";
// import KYCPayment from "./components/Onboarding/KYCPayment";
import KYCPay from "./components/Onboarding/KYCPay"
import Risks from "./components/Onboarding/Risks"
import Upload from "./components/Onboarding/Upload"
import Form from "./components/Onboarding/Index"

// Auth
import AuthLayoutRoute from "./Views/_layouts/authLayout/"
import EmailVerification from "./Views/Auth/EmailVerification"

import Notfound from "./Views/Notfound"

const theme = extendTheme({
  fonts: {
    heading: "Graphik",
    body: "Graphik",
  },
})

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Router>
          <Switch>
            <Redirect exact from='/' to='/form' />
            {/* signin */}
            <AuthLayoutRoute
              exact
              path='/email-verification'
              component={EmailVerification}
            />
            {/* onboarding */}
            <Route exact path='/form' component={Form} />
            <Route
              exact
              path='/onboarding/identification'
              component={Identification}
            />
            <Route
              exact
              path='/onboarding/investment'
              component={InvestmentAccount}
            />
            <Route exact path='/onboarding/kyc-payment' component={KYCPay} />
            {/* <Route
              exact
              path='/onboarding/kyc-payment'
              component={KYCPayment}
            /> */}
            <Route exact path='/onboarding/risks' component={Risks} />
            <Route exact path='/onboarding/upload' component={Upload} />
            <Route component={Notfound} />
          </Switch>
        </Router>
      </ChakraProvider>
    </Provider>
  )
}

export default App;
