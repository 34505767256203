import React from 'react'


const BackButton = ({currentPage, nextPage}) => {
  return (
    <div>
      <div className="back-link cursor-pointer relative" onClick={() => nextPage(currentPage - 1)}>
          <svg className="back-btn" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.11025 7.33569L7.4685 11.8183L6.31962 13L0 6.5L6.31962 0L7.4685 1.18167L3.11025 5.66431H13V7.33569H3.11025Z" fill="#383838"/>
          </svg>  
          <span>Back</span>
        </div>
      </div>
  )
}

export default BackButton