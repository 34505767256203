import { USER } from "../actions/user.action";

const initialState = {
  user: JSON.parse(localStorage.getItem("userData")),
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER:
      return { ...state, name: action.payload };
    default:
      return state;
  }
};

export default userReducer;
