import React from "react"
import "./form_loader.css"

const ModalLoader = () => {
  return (
    <div className='spinner'>
      <div className='rect1'></div>
      <div className='rect2'></div>
      <div className='rect3'></div>
      <div className='rect4'></div>
      <div className='rect5'></div>
    </div>
  )
}

const FormLoader = props => {
  return (
    <div className='modal__load__container' {...props}>
      <div>
        <ModalLoader />
      </div>
    </div>
  )
}

export default FormLoader
