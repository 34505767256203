import React from "react";

const MobileSteps = ({ currentPage }) => {
  return (
    <React.Fragment>
      {currentPage === -1 && (
        <div className="mobile-phase">
          <span>Step 1 of 6</span>
          <p>Accreditation Questionnaire</p>
        </div>
      )}

      {currentPage === 0 && (
        <div className="mobile-phase">
          <span>Step 2 of 6</span>
          <p>Identification</p>
        </div>
      )}

      {currentPage === 1 && (
        <div className="mobile-phase">
          <span>Step 3 of 6</span>
          <p>Investment Activities</p>
        </div>
      )}

      {currentPage === 2 && (
        <div className="mobile-phase">
          <span>Step 4 of 6</span>
          <p>Investment Risk Warnings, Information & Consentent Activities</p>
        </div>
      )}

      {currentPage === 3 && (
        <div className="mobile-phase">
          <span>Step 5 of 6</span>
          <p>Upload Identification</p>
        </div>
      )}

      {currentPage === 4 && (
        <div className="mobile-phase">
          <span>Step 6 of 6</span>
          <p>Admin Fees</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default MobileSteps;
