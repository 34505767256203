import React, { Component } from 'react';
import axios from "axios"
import AddTitleHelmet from "../Helmet"
import FormLoader from "../loader/FormLoader"
import apis from "../../utils/api"
import { updateDropOff } from "../../utils/calls/dropOff.calls"
import { userEmail } from "../../utils/helpers"

const { VERIFICATION_API } = apis
const baseUrl = VERIFICATION_API

class Accreditation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      totalCorrectCount: 7,
      currentCurrectCount: 0,
      fail: false,
      breakEven: false,
      succeed: false,
      becomeListed: false,
      yesLegalObligation: false,
      noWillNotGetMoneyBacck: false,
      yesICanChoose: false,
      entrepreneurInvested: false,
      syndicatePayBack: false,
      unlikelyGetInvestmentBack: false,
      yesDividendRegularBasis: false,
      noStartupsDontPayAtAll: false,
      possiblyPayIfStipulated: false,
      founderBuysShare: false,
      sellSharresAnytime: false,
      iWouldWaitCompareSecureListing: false,
      eighteen: false,
      twoFour: false,
      fourSeven: false,
      nothingHappens: false,
      ownershipIncrease: false,
      ownershipDecrease: false,
      q1Clicked: false,
      q2Clicked: false,
      q3Clicked: false,
      q4Clicked: false,
      q5Clicked: false,
      q6Clicked: false,
      q7Clicked: false,
      q1Answer: "",
      q2Answer: "",
      q3Answer: "",
      q4Answer: "",
      q5Answer: "",
      q6Answer: "",
      q7Answer: "",
      dropOffData: {},
      dropOffLoading: false,
    }
  }

  addDropOff = email => {
    axios
      .post(`${baseUrl}/v1/dropOff/add/${email}`, email)
      .then(({ data }) => {
        return
      })
      .catch(error => {
        console.dir(error)
      })
  }

  accreditate = () => {
    const email = userEmail()

    let {
      fail,
      noWillNotGetMoneyBacck,
      unlikelyGetInvestmentBack,
      possiblyPayIfStipulated,
      fourSeven,
      iWouldWaitCompareSecureListing,
      ownershipDecrease,
    } = this.state

    let updatedDropOffData = {
      quiz_question1: this.state.q1Answer,
      quiz_question2: this.state.q2Answer,
      quiz_question3: this.state.q3Answer,
      quiz_question4: this.state.q4Answer,
      quiz_question5: this.state.q5Answer,
      quiz_question6: this.state.q6Answer,
      quiz_question7: this.state.q7Answer,
      email: email,
      pageno: this.props.currentPage + 1,
    }

    if (
      !fail ||
      !noWillNotGetMoneyBacck ||
      !unlikelyGetInvestmentBack ||
      !fourSeven ||
      !iWouldWaitCompareSecureListing ||
      !ownershipDecrease ||
      !possiblyPayIfStipulated
    ) {
      return
    }

    this.addDropOff(email)
    updateDropOff(updatedDropOffData)
    this.props.setPage(this.props.currentPage + 1)
  }

  toggle = name => {
    switch (name) {
      case "fail":
        this.setState({
          fail: !this.state.fail,
          succeed: false,
          breakEven: false,
          becomeListed: false,
          q1Clicked: true,
          currentCurrectCount: this.state.currentCurrectCount + 1,
        })
        break
      case "succeed":
        this.setState({
          succeed: !this.state.succeed,
          fail: false,
          breakEven: false,
          becomeListed: false,
          q1Clicked: true,
          currentCurrectCount: this.state.currentCurrectCount - 1,
        })
        break
      case "breakEven":
        this.setState({
          breakEven: !this.state.breakEven,
          fail: false,
          succeed: false,
          becomeListed: false,
          q1Clicked: true,
          currentCurrectCount: this.state.currentCurrectCount - 1,
        })
        break
      case "becomeListed":
        this.setState({
          becomeListed: !this.state.becomeListed,
          fail: false,
          succeed: false,
          breakEven: false,
          q1Clicked: true,
          currentCurrectCount: this.state.currentCurrectCount - 1,
        })
        break
      case "yesLegalObligation":
        this.setState({
          yesLegalObligation: !this.state.yesLegalObligation,
          noWillNotGetMoneyBacck: false,
          yesICanChoose: false,
          q2Clicked: true,
          currentCurrectCount: this.state.currentCurrectCount - 1,
        })
        break
      case "noWillNotGetMoneyBacck":
        this.setState({
          noWillNotGetMoneyBacck: !this.state.noWillNotGetMoneyBacck,
          yesLegalObligation: false,
          yesICanChoose: false,
          q2Clicked: true,
          currentCurrectCount: this.state.currentCurrectCount - 1,
        })
        break
      case "yesICanChoose":
        this.setState({
          yesICanChoose: !this.state.yesICanChoose,
          yesLegalObligation: false,
          noWillNotGetMoneyBacck: false,
          q2Clicked: true,
          currentCurrectCount: this.state.currentCurrectCount - 1,
        })
        break
      case "entrepreneurInvested":
        this.setState({
          entrepreneurInvested: !this.state.entrepreneurInvested,
          syndicatePayBack: false,
          unlikelyGetInvestmentBack: false,
          q3Clicked: true,
        })
        break
      case "syndicatePayBack":
        this.setState({
          syndicatePayBack: !this.state.syndicatePayBack,
          entrepreneurInvested: false,
          unlikelyGetInvestmentBack: false,
          q3Clicked: true,
        })
        break
      case "unlikelyGetInvestmentBack":
        this.setState({
          unlikelyGetInvestmentBack: !this.state.unlikelyGetInvestmentBack,
          entrepreneurInvested: false,
          syndicatePayBack: false,
          q3Clicked: true,
        })
        break
      case "yesDividendRegularBasis":
        this.setState({
          yesDividendRegularBasis: !this.state.yesDividendRegularBasis,
          noStartupsDontPayAtAll: false,
          possiblyPayIfStipulated: false,
          q4Clicked: true,
        })
        break
      case "noStartupsDontPayAtAll":
        this.setState({
          noStartupsDontPayAtAll: !this.state.noStartupsDontPayAtAll,
          yesDividendRegularBasis: false,
          possiblyPayIfStipulated: false,
          q4Clicked: true,
        })
        break
      case "possiblyPayIfStipulated":
        this.setState({
          possiblyPayIfStipulated: !this.state.possiblyPayIfStipulated,
          yesDividendRegularBasis: false,
          noStartupsDontPayAtAll: false,
          q4Clicked: true,
        })
        break
      case "eighteen":
        this.setState({
          eighteen: !this.state.eighteen,
          twoFour: false,
          fourSeven: false,
          q5Clicked: true,
        })
        break
      case "twoFour":
        this.setState({
          twoFour: !this.state.twoFour,
          eighteen: false,
          fourSeven: false,
          q5Clicked: true,
        })
        break
      case "fourSeven":
        this.setState({
          fourSeven: !this.state.fourSeven,
          eighteen: false,
          twoFour: false,
          q5Clicked: true,
        })
        break
      case "founderBuysShare":
        this.setState({
          founderBuysShare: !this.state.founderBuysShare,
          sellSharresAnytime: false,
          iWouldWaitCompareSecureListing: false,
          q6Clicked: true,
        })
        break

      case "sellSharresAnytime":
        this.setState({
          sellSharresAnytime: !this.state.sellSharresAnytime,
          founderBuysShare: false,
          iWouldWaitCompareSecureListing: false,
          q6Clicked: true,
        })
        break
      case "iWouldWaitCompareSecureListing":
        this.setState({
          iWouldWaitCompareSecureListing:
            !this.state.iWouldWaitCompareSecureListing,
          sellSharresAnytime: false,
          founderBuysShare: false,
          q6Clicked: true,
        })
        break
      case "ownershipIncrease":
        this.setState({
          ownershipIncrease: !this.state.ownershipIncrease,
          ownershipDecrease: false,
          nothingHappens: false,
          q7Clicked: true,
        })
        break
      case "ownershipDecrease":
        this.setState({
          ownershipDecrease: !this.state.ownershipDecrease,
          ownershipIncrease: false,
          nothingHappens: false,
          q7Clicked: true,
        })
        break
      case "nothingHappens":
        this.setState({
          nothingHappens: !this.state.nothingHappens,
          ownershipIncrease: false,
          ownershipDecrease: false,
          q7Clicked: true,
        })
        break
      default:
        break
    }
  }

  setInitialStates = () => {
    if (!this.state.dropOffLoading && this.state.dropOffData.quiz_question1) {
      this.toggle("fail")
      this.setState({ q1Answer: this.state.dropOffData.quiz_question1 })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.quiz_question2) {
      this.toggle("noWillNotGetMoneyBacck")
      this.setState({ q2Answer: this.state.dropOffData.quiz_question2 })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.quiz_question3) {
      this.toggle("unlikelyGetInvestmentBack")
      this.setState({ q3Answer: this.state.dropOffData.quiz_question3 })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.quiz_question4) {
      this.toggle("possiblyPayIfStipulated")
      this.setState({ q4Answer: this.state.dropOffData.quiz_question4 })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.quiz_question5) {
      this.toggle("fourSeven")
      this.setState({ q5Answer: this.state.dropOffData.quiz_question5 })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.quiz_question6) {
      this.toggle("iWouldWaitCompareSecureListing")
      this.setState({ q6Answer: this.state.dropOffData.quiz_question6 })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.quiz_question7) {
      this.toggle("ownershipDecrease")
      this.setState({ q7Answer: this.state.dropOffData.quiz_question7 })
    }
  }

  componentDidMount() {
    const getDropOff = async () => {
      const email = userEmail()
      this.setState({ dropOffLoading: true })

      await axios
        .get(`${baseUrl}/v1/dropOff/get/${email}`)
        .then(res => {
          this.setState({ dropOffData: res.data, dropOffLoading: false })
        })
        .catch(error => {
          this.setState({ dropOffLoading: false })
          console.dir(error)
        })

      this.setInitialStates()
    }
    getDropOff()
  }

  render() {
    let {
      fail,
      succeed,
      breakEven,
      becomeListed,
      yesLegalObligation,
      yesICanChoose,
      noWillNotGetMoneyBacck,
      entrepreneurInvested,
      syndicatePayBack,
      unlikelyGetInvestmentBack,
      yesDividendRegularBasis,
      noStartupsDontPayAtAll,
      possiblyPayIfStipulated,
      eighteen,
      twoFour,
      fourSeven,
      founderBuysShare,
      iWouldWaitCompareSecureListing,
      sellSharresAnytime,
      ownershipIncrease,
      ownershipDecrease,
      nothingHappens,
      q2Clicked,
      q3Clicked,
      q4Clicked,
      q5Clicked,
      q6Clicked,
      q7Clicked,
    } = this.state

    return (
      <React.Fragment>
        <AddTitleHelmet title='Syndicate KYC | Accreditation Quiz' />
        {this.state.dropOffLoading && (
          <FormLoader style={{ backgroundColor: "hsla(0, 50%, 100%, 0.5)" }} />
        )}
        <div className='col-sm-6 col-xs-12'>
          <div className='basic-info'>
            <div className='title-header'>
              <h1>Welcome,</h1>
              <span>
                Take the quiz below to test your knowledge of startup investing.
              </span>
            </div>
            <div name='demo'>
              <div className='wrap'>
                <p className='qstn'>1. What happens to most startups?</p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.fail ? "correct" : "")
                    }
                    onClick={e => {
                      this.toggle("fail")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                  >
                    Fail
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.breakEven
                        ? "wrong"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("breakEven")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                  >
                    Break-even
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.succeed
                        ? "wrong"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("succeed")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                  >
                    Succeed
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.becomeListed
                        ? "wrong"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("becomeListed")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                  >
                    Become Listed
                  </div>
                </div>
                {(becomeListed || breakEven || succeed) && (
                  <div className='flex disclaimer wrong-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 6.0102L5.0204 4.0299L4.0299 5.0204L6.0102 7L4.0299 8.9796L5.0204 9.9701L7 7.9898L8.9796 9.9701L9.9701 8.9796L7.9898 7L9.9701 5.0204L8.9796 4.0299L7 6.0102Z'
                        fill='#EF4813'
                      />
                    </svg>
                    <span>You’ve selected the wrong answer, Try Again</span>
                  </div>
                )}

                {fail && (
                  <div className='flex disclaimer correct-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 0C3.11818 0 0 3.11818 0 7C0 10.8818 3.11818 14 7 14C10.8818 14 14 10.8818 14 7C14 3.11818 10.8818 0 7 0ZM11.0409 4.80455L5.95 10.0864C5.88636 10.15 5.82273 10.1818 5.72727 10.1818C5.63182 10.1818 5.53636 10.15 5.50455 10.0864L3.02273 7.41364L2.95909 7.35C2.89545 7.28636 2.86364 7.19091 2.86364 7.12727C2.86364 7.06364 2.89545 6.96818 2.95909 6.90455L3.40455 6.45909C3.53182 6.33182 3.72273 6.33182 3.85 6.45909L3.88182 6.49091L5.63182 8.36818C5.69546 8.43182 5.79091 8.43182 5.85455 8.36818L10.1182 3.94545H10.15C10.2773 3.81818 10.4682 3.81818 10.5955 3.94545L11.0409 4.39091C11.1682 4.48636 11.1682 4.67727 11.0409 4.80455Z'
                        fill='#3BB273'
                      />
                    </svg>

                    <span>You’ve selected the right answer.</span>
                  </div>
                )}
              </div>

              <div className='wrap'>
                <p className='qstn'>
                  2. If I invest in a startup, can I get my money back at any
                  time?
                </p>

                <div className='flex flex-column mb-3'>
                  <div
                    onClick={e => {
                      this.toggle("yesLegalObligation")
                      this.setState({ q2Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q2Clicked && yesLegalObligation ? "wrong" : "")
                    }
                  >
                    Yes, the company has a legal obligation to pay me back at
                    any time I request for my money
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("noWillNotGetMoneyBacck")
                      this.setState({ q2Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q2Clicked && noWillNotGetMoneyBacck ? "correct" : "")
                    }
                  >
                    No, I won't get my money back until the company is listed,
                    acquired or involved in a merger.
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("yesICanChoose")
                      this.setState({ q2Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q2Clicked && yesICanChoose ? "wrong" : "")
                    }
                  >
                    Yes, I can choose to sell my investment to anyone at any
                    given time
                  </div>
                </div>
                {(yesICanChoose || yesLegalObligation) && (
                  <div className='flex disclaimer wrong-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 6.0102L5.0204 4.0299L4.0299 5.0204L6.0102 7L4.0299 8.9796L5.0204 9.9701L7 7.9898L8.9796 9.9701L9.9701 8.9796L7.9898 7L9.9701 5.0204L8.9796 4.0299L7 6.0102Z'
                        fill='#EF4813'
                      />
                    </svg>
                    <span>You’ve selected the wrong answer, Try Again</span>
                  </div>
                )}
                {noWillNotGetMoneyBacck && (
                  <div className='flex disclaimer correct-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 0C3.11818 0 0 3.11818 0 7C0 10.8818 3.11818 14 7 14C10.8818 14 14 10.8818 14 7C14 3.11818 10.8818 0 7 0ZM11.0409 4.80455L5.95 10.0864C5.88636 10.15 5.82273 10.1818 5.72727 10.1818C5.63182 10.1818 5.53636 10.15 5.50455 10.0864L3.02273 7.41364L2.95909 7.35C2.89545 7.28636 2.86364 7.19091 2.86364 7.12727C2.86364 7.06364 2.89545 6.96818 2.95909 6.90455L3.40455 6.45909C3.53182 6.33182 3.72273 6.33182 3.85 6.45909L3.88182 6.49091L5.63182 8.36818C5.69546 8.43182 5.79091 8.43182 5.85455 8.36818L10.1182 3.94545H10.15C10.2773 3.81818 10.4682 3.81818 10.5955 3.94545L11.0409 4.39091C11.1682 4.48636 11.1682 4.67727 11.0409 4.80455Z'
                        fill='#3BB273'
                      />
                    </svg>

                    <span>You’ve selected the right answer.</span>
                  </div>
                )}
              </div>

              <div className='wrap'>
                <p className='qstn'>
                  3. What happens if I invest in a startup and it fails?
                </p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    onClick={e => {
                      this.toggle("entrepreneurInvested")
                      this.setState({ q3Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q3Clicked && entrepreneurInvested ? "wrong" : "")
                    }
                  >
                    The entrepreneur who founded the business will pay back the
                    amount invested
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("syndicatePayBack")
                      this.setState({ q3Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q3Clicked && syndicatePayBack ? "wrong" : "")
                    }
                  >
                    The syndicate will pay me back
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("unlikelyGetInvestmentBack")
                      this.setState({ q3Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q3Clicked && unlikelyGetInvestmentBack ? "correct" : "")
                    }
                  >
                    It is unlikely that I get my investment back
                  </div>
                </div>

                {(entrepreneurInvested || syndicatePayBack) && (
                  <div className='flex disclaimer wrong-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 6.0102L5.0204 4.0299L4.0299 5.0204L6.0102 7L4.0299 8.9796L5.0204 9.9701L7 7.9898L8.9796 9.9701L9.9701 8.9796L7.9898 7L9.9701 5.0204L8.9796 4.0299L7 6.0102Z'
                        fill='#EF4813'
                      />
                    </svg>
                    <span>You’ve selected the wrong answer, Try Again</span>
                  </div>
                )}

                {unlikelyGetInvestmentBack && (
                  <div className='flex disclaimer correct-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 0C3.11818 0 0 3.11818 0 7C0 10.8818 3.11818 14 7 14C10.8818 14 14 10.8818 14 7C14 3.11818 10.8818 0 7 0ZM11.0409 4.80455L5.95 10.0864C5.88636 10.15 5.82273 10.1818 5.72727 10.1818C5.63182 10.1818 5.53636 10.15 5.50455 10.0864L3.02273 7.41364L2.95909 7.35C2.89545 7.28636 2.86364 7.19091 2.86364 7.12727C2.86364 7.06364 2.89545 6.96818 2.95909 6.90455L3.40455 6.45909C3.53182 6.33182 3.72273 6.33182 3.85 6.45909L3.88182 6.49091L5.63182 8.36818C5.69546 8.43182 5.79091 8.43182 5.85455 8.36818L10.1182 3.94545H10.15C10.2773 3.81818 10.4682 3.81818 10.5955 3.94545L11.0409 4.39091C11.1682 4.48636 11.1682 4.67727 11.0409 4.80455Z'
                        fill='#3BB273'
                      />
                    </svg>

                    <span>You’ve selected the right answer.</span>
                  </div>
                )}
              </div>

              <div className='wrap'>
                <p className='qstn'>
                  4. Do startups make regular distributions or dividend payments
                  for investments made?
                </p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    onClick={e => {
                      this.toggle("yesDividendRegularBasis")
                      this.setState({ q4Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q4Clicked && yesDividendRegularBasis ? "wrong" : "")
                    }
                  >
                    Yes, startups pay dividends to their investors on a regular
                    basis
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("noStartupsDontPayAtAll")
                      this.setState({ q4Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q4Clicked && noStartupsDontPayAtAll ? "wrong" : "")
                    }
                  >
                    No, startups do not pay investment at all
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("possiblyPayIfStipulated")
                      this.setState({ q4Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q4Clicked && possiblyPayIfStipulated ? "correct" : "")
                    }
                  >
                    Possibly, some startups may pay if stipulated in the
                    investment deed.
                  </div>
                </div>

                {(yesDividendRegularBasis || noStartupsDontPayAtAll) && (
                  <div className='flex disclaimer wrong-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 6.0102L5.0204 4.0299L4.0299 5.0204L6.0102 7L4.0299 8.9796L5.0204 9.9701L7 7.9898L8.9796 9.9701L9.9701 8.9796L7.9898 7L9.9701 5.0204L8.9796 4.0299L7 6.0102Z'
                        fill='#EF4813'
                      />
                    </svg>
                    <span>You’ve selected the wrong answer, Try Again</span>
                  </div>
                )}

                {possiblyPayIfStipulated && (
                  <div className='flex disclaimer correct-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 0C3.11818 0 0 3.11818 0 7C0 10.8818 3.11818 14 7 14C10.8818 14 14 10.8818 14 7C14 3.11818 10.8818 0 7 0ZM11.0409 4.80455L5.95 10.0864C5.88636 10.15 5.82273 10.1818 5.72727 10.1818C5.63182 10.1818 5.53636 10.15 5.50455 10.0864L3.02273 7.41364L2.95909 7.35C2.89545 7.28636 2.86364 7.19091 2.86364 7.12727C2.86364 7.06364 2.89545 6.96818 2.95909 6.90455L3.40455 6.45909C3.53182 6.33182 3.72273 6.33182 3.85 6.45909L3.88182 6.49091L5.63182 8.36818C5.69546 8.43182 5.79091 8.43182 5.85455 8.36818L10.1182 3.94545H10.15C10.2773 3.81818 10.4682 3.81818 10.5955 3.94545L11.0409 4.39091C11.1682 4.48636 11.1682 4.67727 11.0409 4.80455Z'
                        fill='#3BB273'
                      />
                    </svg>

                    <span>You’ve selected the right answer.</span>
                  </div>
                )}

                {/* <p><input type="radio" name="regularDividendPayments" value="yesStartupsPay" onChange={this.props.onHandleChange}  />Yes, startups pay dividents
                          to their investors
                          on a regular basis</p>

                      <p><input type="radio" name="regularDividendPayments" value="No, startups do not" onChange={this.props.onHandleChange}  />No, startups do not pay
                          investment at all</p>

                      <p><input type="radio" name="regularDividendPayments" value="yes" onChange={this.props.onHandleChange}  />Possibly, some startups may pay if stipulated in the investment deed.</p>
                   */}
              </div>

              <div className='wrap'>
                <p className='qstn'>
                  5. How long do I typically have to wait to get returns on a
                  successful investment?
                </p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    onClick={e => {
                      this.toggle("eighteen")
                      this.setState({ q5Answer: e.target.innerText })
                    }}
                    className={
                      "badges " + (q5Clicked && eighteen ? "wrong" : "")
                    }
                  >
                    18 months
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("twoFour")
                      this.setState({ q5Answer: e.target.innerText })
                    }}
                    className={
                      "badges " + (q5Clicked && twoFour ? "wrong" : "")
                    }
                  >
                    2 - 4 years
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("fourSeven")
                      this.setState({ q5Answer: e.target.innerText })
                    }}
                    className={
                      "badges " + (q5Clicked && fourSeven ? "correct" : "")
                    }
                  >
                    4 - 7 years
                  </div>
                </div>

                {(eighteen || twoFour) && (
                  <div className='flex disclaimer wrong-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 6.0102L5.0204 4.0299L4.0299 5.0204L6.0102 7L4.0299 8.9796L5.0204 9.9701L7 7.9898L8.9796 9.9701L9.9701 8.9796L7.9898 7L9.9701 5.0204L8.9796 4.0299L7 6.0102Z'
                        fill='#EF4813'
                      />
                    </svg>
                    <span>You’ve selected the wrong answer, Try Again</span>
                  </div>
                )}

                {fourSeven && (
                  <div className='flex disclaimer correct-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 0C3.11818 0 0 3.11818 0 7C0 10.8818 3.11818 14 7 14C10.8818 14 14 10.8818 14 7C14 3.11818 10.8818 0 7 0ZM11.0409 4.80455L5.95 10.0864C5.88636 10.15 5.82273 10.1818 5.72727 10.1818C5.63182 10.1818 5.53636 10.15 5.50455 10.0864L3.02273 7.41364L2.95909 7.35C2.89545 7.28636 2.86364 7.19091 2.86364 7.12727C2.86364 7.06364 2.89545 6.96818 2.95909 6.90455L3.40455 6.45909C3.53182 6.33182 3.72273 6.33182 3.85 6.45909L3.88182 6.49091L5.63182 8.36818C5.69546 8.43182 5.79091 8.43182 5.85455 8.36818L10.1182 3.94545H10.15C10.2773 3.81818 10.4682 3.81818 10.5955 3.94545L11.0409 4.39091C11.1682 4.48636 11.1682 4.67727 11.0409 4.80455Z'
                        fill='#3BB273'
                      />
                    </svg>

                    <span>You’ve selected the right answer.</span>
                  </div>
                )}
              </div>

              <div className='wrap'>
                <p className='qstn'>
                  6. What happens if I decided to sell my shares and exit a
                  successful company?
                </p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    onClick={e => {
                      this.toggle("founderBuysShare")
                      this.setState({ q6Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q6Clicked && founderBuysShare ? "wrong" : "")
                    }
                  >
                    The company founders would buy back my shares
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("sellSharresAnytime")
                      this.setState({ q6Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q6Clicked && sellSharresAnytime ? "wrong" : "")
                    }
                  >
                    I can sell my shares to anyone at any time
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("iWouldWaitCompareSecureListing")
                      this.setState({ q6Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q6Clicked && iWouldWaitCompareSecureListing
                        ? "correct"
                        : "")
                    }
                  >
                    I would have to wait for the company to secure a listing
                    event or a secondary sale otherwise it would be difficult to
                    exit the investment
                  </div>
                </div>

                {(founderBuysShare || sellSharresAnytime) && (
                  <div className='flex disclaimer wrong-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 6.0102L5.0204 4.0299L4.0299 5.0204L6.0102 7L4.0299 8.9796L5.0204 9.9701L7 7.9898L8.9796 9.9701L9.9701 8.9796L7.9898 7L9.9701 5.0204L8.9796 4.0299L7 6.0102Z'
                        fill='#EF4813'
                      />
                    </svg>
                    <span>You’ve selected the wrong answer, Try Again</span>
                  </div>
                )}

                {iWouldWaitCompareSecureListing && (
                  <div className='flex disclaimer correct-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 0C3.11818 0 0 3.11818 0 7C0 10.8818 3.11818 14 7 14C10.8818 14 14 10.8818 14 7C14 3.11818 10.8818 0 7 0ZM11.0409 4.80455L5.95 10.0864C5.88636 10.15 5.82273 10.1818 5.72727 10.1818C5.63182 10.1818 5.53636 10.15 5.50455 10.0864L3.02273 7.41364L2.95909 7.35C2.89545 7.28636 2.86364 7.19091 2.86364 7.12727C2.86364 7.06364 2.89545 6.96818 2.95909 6.90455L3.40455 6.45909C3.53182 6.33182 3.72273 6.33182 3.85 6.45909L3.88182 6.49091L5.63182 8.36818C5.69546 8.43182 5.79091 8.43182 5.85455 8.36818L10.1182 3.94545H10.15C10.2773 3.81818 10.4682 3.81818 10.5955 3.94545L11.0409 4.39091C11.1682 4.48636 11.1682 4.67727 11.0409 4.80455Z'
                        fill='#3BB273'
                      />
                    </svg>

                    <span>You’ve selected the right answer.</span>
                  </div>
                )}
              </div>

              <div className='wrap'>
                <p className='qstn'>
                  7. What happens when a startup raises additional funds after I
                  make an investment?
                </p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    onClick={e => {
                      this.toggle("ownershipIncrease")
                      this.setState({ q7Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q7Clicked && ownershipIncrease ? "wrong" : "")
                    }
                  >
                    My ownership will increase in the company
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("ownershipDecrease")
                      this.setState({ q7Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q7Clicked && ownershipDecrease ? "correct" : "")
                    }
                  >
                    My ownership will decrease in the company
                  </div>
                  <div
                    onClick={e => {
                      this.toggle("nothingHappens")
                      this.setState({ q7Answer: e.target.innerText })
                    }}
                    className={
                      "badges full " +
                      (q7Clicked && nothingHappens ? "wrong" : "")
                    }
                  >
                    Nothing happens, the company takes the money and includes
                    new investors to the current list of investors
                  </div>
                </div>

                {(ownershipIncrease || nothingHappens) && (
                  <div className='flex disclaimer wrong-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 14C3.1339 14 0 10.8661 0 7C0 3.1339 3.1339 0 7 0C10.8661 0 14 3.1339 14 7C14 10.8661 10.8661 14 7 14ZM7 6.0102L5.0204 4.0299L4.0299 5.0204L6.0102 7L4.0299 8.9796L5.0204 9.9701L7 7.9898L8.9796 9.9701L9.9701 8.9796L7.9898 7L9.9701 5.0204L8.9796 4.0299L7 6.0102Z'
                        fill='#EF4813'
                      />
                    </svg>
                    <span>You’ve selected the wrong answer, Try Again</span>
                  </div>
                )}

                {ownershipDecrease && (
                  <div className='flex disclaimer correct-answer'>
                    <svg
                      width='14'
                      height='14'
                      viewBox='0 0 14 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M7 0C3.11818 0 0 3.11818 0 7C0 10.8818 3.11818 14 7 14C10.8818 14 14 10.8818 14 7C14 3.11818 10.8818 0 7 0ZM11.0409 4.80455L5.95 10.0864C5.88636 10.15 5.82273 10.1818 5.72727 10.1818C5.63182 10.1818 5.53636 10.15 5.50455 10.0864L3.02273 7.41364L2.95909 7.35C2.89545 7.28636 2.86364 7.19091 2.86364 7.12727C2.86364 7.06364 2.89545 6.96818 2.95909 6.90455L3.40455 6.45909C3.53182 6.33182 3.72273 6.33182 3.85 6.45909L3.88182 6.49091L5.63182 8.36818C5.69546 8.43182 5.79091 8.43182 5.85455 8.36818L10.1182 3.94545H10.15C10.2773 3.81818 10.4682 3.81818 10.5955 3.94545L11.0409 4.39091C11.1682 4.48636 11.1682 4.67727 11.0409 4.80455Z'
                        fill='#3BB273'
                      />
                    </svg>

                    <span>You’ve selected the right answer.</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='pagination'>
            <div>
              <div
                onClick={this.accreditate}
                className={
                  "link " +
                  (!fail ||
                    !noWillNotGetMoneyBacck ||
                    !fourSeven ||
                    !iWouldWaitCompareSecureListing ||
                    !ownershipDecrease)
                }
              >
                Next
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Accreditation