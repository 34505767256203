import React, { Component } from 'react';
import axios from "axios"
import AddTitleHelmet from "../Helmet"
import BackButton from "../UI/BackBtn"
import apis from "../../utils/api"
import { updateDropOff } from "../../utils/calls/dropOff.calls"
import { userEmail } from "../../utils/helpers"
import FormLoader from "../loader/FormLoader"

const { VERIFICATION_API } = apis
const baseUrl = VERIFICATION_API
export default class InvestmentAccount extends Component {
  constructor(props) {
    super(props)
    this.acceptTermsRef = React.createRef()
  }

  state = {
    acceptTerms: false,
    error: "",
    hasError: false,
    planToInvest100Less: false,
    planToInvest10025: false,
    planToInvest5010: false,
    planToInvest5001M: false,
    investmentsPerYearOneThree: false,
    investmentsPerYearThreeFive: false,
    investmentsPerYearFiveSeven: false,
    howWillYouInvestIndividual: false,
    howWillYouInvestInstitution: false,
    howWillYouInvestInvestmentGroup: false,
    healthTech: false,
    governance: false,
    smartInfra: false,
    cleanEnergy: false,
    edTech: false,
    consumerInternet: false,
    hardware: false,
    saas: false,
    iot: false,
    digitalSecurity: false,
    agriTech: false,
    mobility: false,
    allSectors: false,
    yesEvaluateRisk: false,
    noEvaluateRisk: false,
    yesInvesting: false,
    noInvesting: false,
    annualIncomeZero: false,
    annualIncomeLt50k: false,
    annualIncomeLt250k: false,
    annualIncomeMt250k: false,
    totalNetAssetsZero: false,
    totalNetAssetsLt250: false,
    totalNetAssetsLt500: false,
    totalNetAssetsLt1M: false,
    totalNetAssetsLt5M: false,
    investmentStages: [],
    investmentSectors: [],
    q1Clicked: false,
    q2Clicked: false,
    q3Clicked: false,
    q4Clicked: false,
    q5Clicked: false,
    q1Answer: "",
    q2Answer: "",
    q3Answer: "",
    q4Answer: "",
    q7Answer: "",
    q8Answer: "",
    q9Answer: "",
    dropOffData: {},
    dropOffLoading: false,

    startupInvestedIn: "",
  }

  setStage = name => {
    if (this.state.investmentStages.length === 0) {
      let investments = []
      investments.push(name)
      this.setState({ investmentStages: investments })
      return
    }
    //If the item is already in the array, remove it from the 🚛
    let alreadyFound = this.hasInvestment(name)
    if (alreadyFound) {
      let investments = this.state.investmentStages.filter(
        item => item !== name
      )
      this.setState({ investmentStages: investments })
      return
    } else {
      let i = []
      i.push(name)
      this.setState({
        investmentStages: [...this.state.investmentStages, ...i],
      })
      return
    }
  }

  setSectors = name => {
    if (this.state.investmentSectors.length === 0) {
      let sectors = []
      sectors.push(name)
      this.setState({ investmentSectors: sectors })
      return
    }
    //If the item is already in the array, remove it from the 🚛
    let alreadyFound = this.hasSector(name)
    if (alreadyFound) {
      let sectors = this.state.investmentSectors.filter(item => item !== name)
      this.setState({ investmentSectors: sectors })
      return
    } else {
      let i = []
      i.push(name)
      this.setState({
        investmentSectors: [...this.state.investmentSectors, ...i],
      })
      return
    }
  }

  hasInvestment = name => {
    return this.state.investmentStages.includes(name)
  }

  hasSector = name => {
    return this.state.investmentSectors.includes(name)
  }

  toggle = name => {
    switch (name) {
      case "planToInvest100Less":
        this.setState({
          planToInvest100Less: !this.state.planToInvest100Less,
          planToInvest10025: false,
          planToInvest5010: false,
          planToInvest5001M: false,
          q1Clicked: true,
        })
        break

      case "planToInvest10025":
        this.setState({
          planToInvest10025: !this.state.planToInvest10025,
          planToInvest100Less: false,
          planToInvest5010: false,
          planToInvest5001M: false,
          q1Clicked: true,
        })
        break

      case "planToInvest5010":
        this.setState({
          planToInvest5010: !this.state.planToInvest5010,
          planToInvest100Less: false,
          planToInvest10025: false,
          planToInvest5001M: false,
          q1Clicked: true,
        })
        break

      case "planToInvest5001M":
        this.setState({
          planToInvest5001M: !this.state.planToInvest5001M,
          planToInvest100Less: false,
          planToInvest10025: false,
          planToInvest5010: false,
          q1Clicked: true,
        })
        break

      case "investmentsPerYearOneThree":
        this.setState({
          investmentsPerYearOneThree: !this.state.investmentsPerYearOneThree,
          investmentsPerYearThreeFive: false,
          investmentsPerYearFiveSeven: false,
          q2Clicked: true,
        })
        break
      case "investmentsPerYearThreeFive":
        this.setState({
          investmentsPerYearThreeFive: !this.state.investmentsPerYearThreeFive,
          investmentsPerYearOneThree: false,
          investmentsPerYearFiveSeven: false,
          q2Clicked: true,
        })
        break
      case "investmentsPerYearFiveSeven":
        this.setState({
          investmentsPerYearFiveSeven: !this.state.investmentsPerYearFiveSeven,
          investmentsPerYearOneThree: false,
          investmentsPerYearThreeFive: false,
          q2Clicked: true,
        })
        break

      case "yesInvesting":
        this.setState({
          yesInvesting: !this.state.yesInvesting,
          noInvesting: false,
          q3Clicked: true,
        })
        break
      case "noInvesting":
        this.setState({
          noInvesting: !this.state.noInvesting,
          yesInvesting: false,
          q3Clicked: true,
        })
        break

      case "howWillYouInvestIndividual":
        this.setState({
          howWillYouInvestIndividual: !this.state.howWillYouInvestIndividual,
          howWillYouInvestInstitution: false,
          howWillYouInvestInvestmentGroup: false,
          q4Clicked: true,
        })
        break

      case "howWillYouInvestInstitution":
        this.setState({
          howWillYouInvestInstitution: !this.state.howWillYouInvestInstitution,
          howWillYouInvestIndividual: false,
          howWillYouInvestInvestmentGroup: false,
          q4Clicked: true,
        })
        break
      case "howWillYouInvestInvestmentGroup":
        this.setState({
          howWillYouInvestInvestmentGroup:
            !this.state.howWillYouInvestInvestmentGroup,
          howWillYouInvestIndividual: false,
          howWillYouInvestInstitution: false,
          q4Clicked: true,
        })
        break

      case "yesEvaluateRisk":
        this.setState({
          yesEvaluateRisk: !this.state.yesEvaluateRisk,
          noEvaluateRisk: false,
          q7Clicked: true,
        })
        break
      case "noEvaluateRisk":
        this.setState({
          noEvaluateRisk: !this.state.noEvaluateRisk,
          yesEvaluateRisk: false,
          q7Clicked: true,
        })
        break
      case "annualIncomeZero":
        this.setState({
          annualIncomeZero: !this.state.annualIncomeZero,
          annualIncomeLt50k: false,
          annualIncomeLt250k: false,
          annualIncomeMt250k: false,
          q8Clicked: true,
        })
        break

      case "annualIncomeLt50k":
        this.setState({
          annualIncomeLt50k: !this.state.annualIncomeLt50k,
          annualIncomeLt250k: false,
          annualIncomeMt250k: false,
          q8Clicked: true,
        })
        break

      case "annualIncomeLt250k":
        this.setState({
          annualIncomeLt250k: !this.state.annualIncomeLt250k,
          annualIncomeLt50k: false,
          annualIncomeMt250k: false,
          q8Clicked: true,
        })
        break

      case "annualIncomeMt250k":
        this.setState({
          annualIncomeMt250k: !this.state.annualIncomeMt250k,
          annualIncomeLt50k: false,
          annualIncomeLt250k: false,
          q8Clicked: true,
        })
        break

      case "totalNetAssetsZero":
        this.setState({
          totalNetAssetsZero: !this.state.totalNetAssetsZero,
          totalNetAssetsLt250: false,
          totalNetAssetsLt500: false,
          totalNetAssetsLt1M: false,
          totalNetAssetsLt5M: false,
          q9Clicked: true,
        })
        break
      case "totalNetAssetsLt250":
        this.setState({
          totalNetAssetsLt250: !this.state.totalNetAssetsLt250,
          totalNetAssetsLt500: false,
          totalNetAssetsLt1M: false,
          totalNetAssetsLt5M: false,
          q9Clicked: true,
        })
        break

      case "totalNetAssetsLt500":
        this.setState({
          totalNetAssetsLt500: !this.state.totalNetAssetsLt500,
          totalNetAssetsLt250: false,
          totalNetAssetsLt1M: false,
          totalNetAssetsLt5M: false,
          q9Clicked: true,
        })
        break

      case "totalNetAssetsLt1M":
        this.setState({
          totalNetAssetsLt1M: !this.state.totalNetAssetsLt1M,
          totalNetAssetsLt250: false,
          totalNetAssetsLt500: false,
          totalNetAssetsLt5M: false,
          q9Clicked: true,
        })
        break

      case "totalNetAssetsLt5M":
        this.setState({
          totalNetAssetsLt5M: !this.state.totalNetAssetsLt5M,
          totalNetAssetsLt250: false,
          totalNetAssetsLt500: false,
          totalNetAssetsLt1M: false,
          q9Clicked: true,
        })
        break
      default:
        break
    }
  }

  validatePlanToInvest = () => {
    let {
      planToInvest100Less,
      planToInvest5001M,
      planToInvest5010,
      planToInvest10025,
    } = this.state

    let obj = {
      planToInvest100Less: "$10,000 or less",
      planToInvest10025: "$10,000 to $25,000",
      planToInvest5010: "$50,000 to $100,000",
      planToInvest5001M: "$500,000 to $1,000,000+",
    }

    if (
      !planToInvest100Less &&
      !planToInvest5001M &&
      !planToInvest5010 &&
      !planToInvest10025
    ) {
      return
    }

    if (planToInvest100Less) {
      this.props.setItem("planToInvest", obj["planToInvest100Less"])
    } else if (planToInvest5001M) {
      this.props.setItem("planToInvest", obj["planToInvest5001M"])
    } else if (planToInvest5010) {
      this.props.setItem("planToInvest", obj["planToInvest5010"])
    } else if (planToInvest10025) {
      this.props.setItem("planToInvest", obj["planToInvest10025"])
    }
  }

  validateInvestmentsPerYear = () => {
    let {
      investmentsPerYearFiveSeven,
      investmentsPerYearOneThree,
      investmentsPerYearThreeFive,
    } = this.state

    let obj = {
      investmentsPerYearOneThree: "1-3",
      investmentsPerYearThreeFive: "3-5",
      investmentsPerYearFiveSeven: "5-7",
    }

    if (
      !investmentsPerYearFiveSeven &&
      !investmentsPerYearOneThree &&
      !investmentsPerYearThreeFive
    ) {
      return
    }

    if (investmentsPerYearOneThree) {
      this.props.setItem(
        "investmentsPerYear",
        obj["investmentsPerYearOneThree"]
      )
    } else if (investmentsPerYearThreeFive) {
      this.props.setItem(
        "investmentsPerYear",
        obj["investmentsPerYearThreeFive"]
      )
    } else if (investmentsPerYearFiveSeven) {
      this.props.setItem(
        "investmentsPerYear",
        obj["investmentsPerYearFiveSeven"]
      )
    }
  }

  validateExperienceInvesting = () => {
    let { yesInvesting, noInvesting } = this.state

    if (!yesInvesting && !noInvesting) {
      return
    }

    if (yesInvesting && !this.state.startupInvestedIn) {
      alert("Add a startup you've invested in.")
      return
    }

    if (yesInvesting) {
      this.props.setItem("experienceInvesting", "yes")
      return
    } else if (noInvesting) {
      this.props.setItem("experienceInvesting", "no")
      return
    }
  }

  validateHowWillYouInvest = () => {
    let {
      howWillYouInvestIndividual,
      howWillYouInvestInstitution,
      howWillYouInvestInvestmentGroup,
    } = this.state

    if (
      !howWillYouInvestIndividual &&
      !howWillYouInvestInstitution &&
      !howWillYouInvestInvestmentGroup
    ) {
      return
    }

    if (howWillYouInvestIndividual) {
      this.props.setItem("howWillYouInvest", "As an individual")
    } else if (howWillYouInvestInstitution) {
      this.props.setItem("howWillYouInvest", "As a staff within an institution")
    } else if (howWillYouInvestInvestmentGroup) {
      this.props.setItem(
        "howWillYouInvest",
        "As a member of an investing group, angel network or Syndicate"
      )
    }
  }

  validateSector = () => {
    if (this.state.investmentSectors.length === 0) {
      return
    }
    let sectors = this.state.investmentSectors.join("; ")
    this.props.setItem("investmentSectors", sectors)
  }

  validateInvestmentStage = () => {
    if (this.state.investmentStages.length === 0) {
      return
    }
    let investmentStages = this.state.investmentStages.join("; ")
    this.props.setItem("investmentStage", investmentStages)
  }
  validateEvaluateRisks = () => {
    let { yesEvaluateRisk, noEvaluateRisk } = this.state

    if (!yesEvaluateRisk && !noEvaluateRisk) {
      return
    }

    if (yesEvaluateRisk) {
      this.props.setItem("canEvaluateRisk", "yes")
    } else if (noEvaluateRisk) {
      this.props.setItem("canEvaluateRisk", "no")
    }
  }

  validateAnnualIncome = () => {
    let { annualIncomeLt50k, annualIncomeLt250k, annualIncomeMt250k } =
      this.state

    if (!annualIncomeLt250k && !annualIncomeMt250k && !annualIncomeLt50k) {
      return
    }

    let obj = {
      annualIncomeLt50k: "Less than $50,000",
      annualIncomeLt250k: "$50,000 - $250,000",
      annualIncomeMt250k: "More than $250,000",
    }

    if (annualIncomeLt50k) {
      this.props.setItem("annualIncome", obj["annualIncomeLt50k"])
    } else if (annualIncomeLt250k) {
      this.props.setItem("annualIncome", obj["annualIncomeLt250k"])
    } else if (annualIncomeMt250k) {
      this.props.setItem("annualIncome", obj["annualIncomeMt250k"])
    }
  }

  validateTotalNetAsset = () => {
    let {
      totalNetAssetsLt250,
      totalNetAssetsLt500,
      totalNetAssetsLt1M,
      totalNetAssetsLt5M,
    } = this.state

    if (
      !totalNetAssetsLt1M &&
      !totalNetAssetsLt250 &&
      !totalNetAssetsLt500 &&
      !totalNetAssetsLt5M
    ) {
      return
    }

    let obj = {
      totalNetAssetsLt250: "Under $250,000 ",
      totalNetAssetsLt500: "$250,000 - $500,000",
      totalNetAssetsLt1M: "$500,000 - $1,000,000",
      totalNetAssetsLt5M: "$1m - $5m",
    }

    if (totalNetAssetsLt250) {
      this.props.setItem("totalNetAssets", obj["totalNetAssetsLt250"])
    } else if (totalNetAssetsLt500) {
      this.props.setItem("totalNetAssets", obj["totalNetAssetsLt500"])
    } else if (totalNetAssetsLt1M) {
      this.props.setItem("totalNetAssets", obj["totalNetAssetsLt1M"])
    } else if (totalNetAssetsLt5M) {
      this.props.setItem("totalNetAssets", obj["totalNetAssetsLt5M"])
    }
  }

  setInitialStates = () => {
    if (!this.state.dropOffLoading && this.state.dropOffData.amounttoinvest) {
      this.setState({ q1Answer: this.state.dropOffData.amounttoinvest })

      if (this.state.dropOffData.amounttoinvest === "$10,000 or less") {
        this.toggle("planToInvest100Less")
      }
      if (this.state.dropOffData.amounttoinvest === "$10,000 to $25,000") {
        this.toggle("planToInvest10025")
      }
      if (this.state.dropOffData.amounttoinvest === "$50,000 to $100,000") {
        this.toggle("planToInvest5010")
      }
      if (this.state.dropOffData.amounttoinvest === "$500,000 to $1,000,000+") {
        this.toggle("planToInvest5001M")
      }
    }

    if (
      !this.state.dropOffLoading &&
      this.state.dropOffData.investmentsperyear
    ) {
      this.setState({ q2Answer: this.state.dropOffData.investmentsperyear })

      if (this.state.dropOffData.investmentsperyear === "1-3") {
        this.toggle("investmentsPerYearOneThree")
      }
      if (this.state.dropOffData.investmentsperyear === "3-5") {
        this.toggle("investmentsPerYearThreeFive")
      }
      if (this.state.dropOffData.investmentsperyear === "5-7") {
        this.toggle("investmentsPerYearFiveSeven")
      }
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.experience) {
      this.setState({ q3Answer: this.state.dropOffData.experience })

      if (this.state.dropOffData.experience === "Yes") {
        this.toggle("yesInvesting")
      }
      if (this.state.dropOffData.experience === "No") {
        this.toggle("noInvesting")
      }
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.typeofinvestor) {
      this.setState({ q4Answer: this.state.dropOffData.typeofinvestor })

      if (this.state.dropOffData.typeofinvestor === "As an individual") {
        this.toggle("howWillYouInvestIndividual")
      }
      if (
        this.state.dropOffData.typeofinvestor ===
        "As a staff within an institution"
      ) {
        this.toggle("howWillYouInvestInstitution")
      }
      if (
        this.state.dropOffData.typeofinvestor ===
        "As a member of an investing group, angel network or Syndicate"
      ) {
        this.toggle("howWillYouInvestInvestmentGroup")
      }
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.riskevaluation) {
      this.setState({ q7Answer: this.state.dropOffData.riskevaluation })

      if (this.state.dropOffData.riskevaluation === "Yes") {
        this.toggle("yesEvaluateRisk")
      }
      if (this.state.dropOffData.riskevaluation === "No") {
        this.toggle("noEvaluateRisk")
      }
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.annualincome) {
      this.setState({ q8Answer: this.state.dropOffData.annualincome })

      if (this.state.dropOffData.annualincome === "Less than $50,000") {
        this.toggle("annualIncomeLt50k")
      }
      if (this.state.dropOffData.annualincome === "$50,000 - $250,000") {
        this.toggle("annualIncomeLt250k")
      }
      if (this.state.dropOffData.annualincome === "More than $250,000") {
        this.toggle("annualIncomeMt250k")
      }
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.totalnetassets) {
      this.setState({
        q9Answer: this.state.dropOffData.totalnetassets,
      })

      if (this.state.dropOffData.totalnetassets === "Under $250,000") {
        this.toggle("totalNetAssetsLt250")
      }
      if (this.state.dropOffData.totalnetassets === "$250,000 - $500,000") {
        this.toggle("totalNetAssetsLt500")
      }
      if (this.state.dropOffData.totalnetassets === "$500,000 - $1,000,000") {
        this.toggle("totalNetAssetsLt1M")
      }
      if (this.state.dropOffData.totalnetassets === "$1m - $5m") {
        this.toggle("totalNetAssetsLt5M")
      }
    }

    if (
      !this.state.dropOffLoading &&
      this.state.dropOffData.startupsinvestedin
    ) {
      this.setState({
        startupInvestedIn: this.state.dropOffData.startupsinvestedin
          .split("; ")
          .join("\r\n"),
      })
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.sectors) {
      let sectorsArr = this.state.dropOffData.sectors.split("; ")
      this.setState({
        investmentSectors: [...sectorsArr],
      })
    }

    if (!this.state.dropOffLoading && this.state.dropOffData.stages) {
      let stagessArr = this.state.dropOffData.stages.split("; ")
      this.setState({
        investmentStages: [...stagessArr],
      })
    }

    if (
      !this.state.dropOffLoading &&
      this.state.q1Answer &&
      this.state.q2Answer &&
      this.state.q3Answer &&
      this.state.q4Answer &&
      this.state.q7Answer &&
      this.state.q8Answer &&
      this.state.q9Answer &&
      this.state.investmentSectors.length > 0 &&
      this.state.investmentStages.length > 0
    ) {
      this.setState({ acceptTerms: true })
      this.acceptTermsRef.current.checked = true
    }
  }

  componentDidMount() {
    const getDropOff = async () => {
      const email = userEmail()
      this.setState({ dropOffLoading: true })

      await axios
        .get(`${baseUrl}/v1/dropOff/get/${email}`)
        .then(res => {
          this.setState({
            dropOffData: res.data,
            dropOffLoading: false,
          })
        })
        .catch(error => {
          this.setState({ dropOffLoading: false })
          console.dir(error)
        })

      this.setInitialStates()
    }
    getDropOff()
  }

  submitInvestmentActivities = () => {
    const email = userEmail()

    let {
      planToInvest,
      investmentStage,
      investmentSectors,
      investmentsPerYear,
      howWillYouInvest,
      canEvaluateRisk,
      experienceInvesting,
      annualIncome,
      totalNetAssets,
    } = this.props

    this.validatePlanToInvest()
    this.validateInvestmentsPerYear()
    this.validateExperienceInvesting()
    this.validateHowWillYouInvest()
    this.validateSector()
    this.validateEvaluateRisks()
    this.validateInvestmentStage()
    this.validateAnnualIncome()
    this.validateTotalNetAsset()

    if (!this.state.acceptTerms) {
      this.setState({ error: true })
      return
    }

    if (!planToInvest) return
    if (!investmentsPerYear) return
    if (!investmentSectors) return
    if (!investmentStage) return
    if (!howWillYouInvest) return
    if (!canEvaluateRisk) return
    if (!experienceInvesting) return
    if (!annualIncome) return
    if (!totalNetAssets) return

    if (canEvaluateRisk && canEvaluateRisk === "no") return

    let updatedDropOffData = {
      amounttoinvest: this.state.q1Answer,
      investmentsperyear: this.state.q2Answer,
      experience: this.state.q3Answer,
      typeofinvestor: this.state.q4Answer,
      riskevaluation: this.state.q7Answer,
      annualincome: this.state.q8Answer,
      totalnetassets: this.state.q9Answer,
      sectors: this.props.investmentSectors,
      stages: this.props.investmentStage,
      startupsinvestedin: this.state.startupInvestedIn
        .split(/\r?\n/)
        .join("; "),
      email: email,
      pageno: this.props.currentPage + 1,
    }

    updateDropOff(updatedDropOffData)
    this.props.setPage(this.props.currentPage + 1)
  }

  handleSubmission = e => {
    this.props.setPage(4)
  }

  handleChange = event => {
    var isChecked = event.target.checked
    var item = event.target.value
    this.setState(prevState => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }))
  }

  onHandleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <AddTitleHelmet title='Syndicate KYC | Investment Activities' />
        {this.state.dropOffLoading && (
          <FormLoader style={{ backgroundColor: "hsla(0, 50%, 100%, 0.5)" }} />
        )}
        <div className='col-sm-4 col-xs-12'>
          <div className='basic-info'>
            <div className='title-header hide-on-mobile'>
              <h1>Investment Activities</h1>
            </div>
            <div>
              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.planToInvest
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>How much do you plan to invest?</p>

                <div className='flex flex-wrap mb-3'>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.planToInvest100Less
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("planToInvest100Less")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                    style={{ marginRight: "20px", marginBottom: "14px" }}
                  >
                    $10,000 or less
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.planToInvest10025
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("planToInvest10025")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                    style={{ marginRight: "10px", marginBottom: "14px" }}
                  >
                    $10,000 to $25,000
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.planToInvest5010
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("planToInvest5010")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                    style={{ marginRight: "10px", marginBottom: "14px" }}
                  >
                    $50,000 to $100,000
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q1Clicked && this.state.planToInvest5001M
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("planToInvest5001M")
                      this.setState({ q1Answer: e.target.innerText })
                    }}
                    style={{ marginRight: "10px", marginBottom: "14px" }}
                  >
                    $500,000 to $1,000,000+
                  </div>
                </div>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.investmentsPerYear
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>
                  How many investments do you plan to make in a year?
                </p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    className={
                      "badges " +
                      (this.state.q2Clicked &&
                      this.state.investmentsPerYearOneThree
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("investmentsPerYearOneThree")
                      this.setState({ q2Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    1-3
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q2Clicked &&
                      this.state.investmentsPerYearThreeFive
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("investmentsPerYearThreeFive")
                      this.setState({ q2Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    3-5
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q2Clicked &&
                      this.state.investmentsPerYearFiveSeven
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("investmentsPerYearFiveSeven")
                      this.setState({ q2Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    5-7
                  </div>
                </div>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.experienceInvesting
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>
                  Do you have experience in startup investing?
                </p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    className={
                      "badges " +
                      (this.state.q3Clicked && this.state.yesInvesting
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("yesInvesting")
                      this.setState({ q3Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    Yes
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q3Clicked && this.state.noInvesting
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("noInvesting")
                      this.setState({ q3Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    No
                  </div>
                </div>
              </div>

              <div className='wrap'>
                <p className='qstn'>
                  If Yes, which startups have you invested in? (Hit enter to add
                  new startup).
                </p>
                <textarea
                  name='startupInvestedIn'
                  style={{ width: "100%" }}
                  id=''
                  cols='40'
                  rows='3'
                  defaultValue={this.state.startupInvestedIn || ""}
                  onChange={this.onHandleChange}
                ></textarea>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.howWillYouInvest
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>How will you be investing?</p>

                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    className={
                      "badges full " +
                      (this.state.q4Clicked &&
                      this.state.howWillYouInvestIndividual
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("howWillYouInvestIndividual")
                      this.setState({ q4Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    As an individual
                  </div>
                  <div
                    className={
                      "badges full " +
                      (this.state.q4Clicked &&
                      this.state.howWillYouInvestInstitution
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("howWillYouInvestInstitution")
                      this.setState({ q4Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    As a staff within an institution
                  </div>
                  <div
                    className={
                      "badges full " +
                      (this.state.q4Clicked &&
                      this.state.howWillYouInvestInvestmentGroup
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("howWillYouInvestInvestmentGroup")
                      this.setState({ q4Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    As a member of an investing group, angel network or
                    Syndicate
                  </div>
                </div>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.investmentSectors
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>What sectors do you want to invest in?</p>
                <div className='flex flex-wrap justify-between mb-3'>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Health-Tech") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Health-Tech")}
                  >
                    Health-Tech
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Governance") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Governance")}
                  >
                    Governance
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Smart Infrastructure") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Smart Infrastructure")}
                  >
                    Smart Infrastructure
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Clean energy") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Clean energy")}
                  >
                    Clean energy
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Ed Tech") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Ed Tech")}
                  >
                    EdTech
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Consumer Internet") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Consumer Internet")}
                  >
                    Consumer Internet
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Hardware") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Hardware")}
                  >
                    Hardware
                  </div>
                  <div
                    className={
                      "badges mb-2 " + (this.hasSector("SAAS") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("SAAS")}
                  >
                    SAAS
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Internet Of Things") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Internet Of Things")}
                  >
                    Internet Of Things
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Digital Security") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Digital Security")}
                  >
                    Digital Security
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("AgriTech") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("AgriTech")}
                  >
                    AgriTech
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("Mobility") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("Mobility")}
                  >
                    Mobility
                  </div>
                  <div
                    className={
                      "badges mb-2 " +
                      (this.hasSector("All Sectors") ? "correct" : "")
                    }
                    onClick={() => this.setSectors("All Sectors")}
                  >
                    All Sectors
                  </div>
                </div>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.investmentStage
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>What stages do you want to invest in?</p>
                <p className='p-0 m-0 p-sub'>(You can pick multiple options)</p>

                <div
                  className='flex flex-wrap justify-between mb-3'
                  style={{ marginTop: "20px" }}
                >
                  <div
                    className={
                      "badges " +
                      (this.hasInvestment("Pre-seed") ? "correct" : "")
                    }
                    onClick={() => this.setStage("Pre-seed")}
                    style={{ padding: "20px 40px" }}
                  >
                    Pre-seed
                  </div>
                  <div
                    className={
                      "badges " + (this.hasInvestment("Seed") ? "correct" : "")
                    }
                    onClick={() => this.setStage("Seed")}
                    style={{ padding: "20px 40px" }}
                  >
                    Seed
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.hasInvestment("Series A") ? "correct" : "")
                    }
                    onClick={() => this.setStage("Series A")}
                    style={{ padding: "20px 40px" }}
                  >
                    Series A
                  </div>
                </div>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.canEvaluateRisk
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>
                  Are you able to evaluate the risks and rewards of an
                  investment in a startup or unlisted entity in view of your
                  education, your personal or professional experience?
                </p>

                <div
                  className='flex flex-wrap justify-between mb-3'
                  style={{ marginTop: "20px" }}
                >
                  <div
                    className={
                      "badges " +
                      (this.state.q7Clicked && this.state.yesEvaluateRisk
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("yesEvaluateRisk")
                      this.setState({ q7Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    Yes
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q7Clicked && this.state.noEvaluateRisk
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("noEvaluateRisk")
                      this.setState({ q7Answer: e.target.innerText })
                    }}
                    style={{ padding: "20px 40px" }}
                  >
                    No
                  </div>
                </div>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.annualIncome
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>What is your annual income in USD?</p>

                <div
                  className='flex flex-wrap justify-between mb-3'
                  style={{ marginTop: "20px" }}
                >
                  <div
                    className={
                      "badges " +
                      (this.state.q8Clicked && this.state.annualIncomeLt50k
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("annualIncomeLt50k")
                      this.setState({ q8Answer: e.target.innerText })
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    Less than $50,000
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q8Clicked && this.state.annualIncomeLt250k
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("annualIncomeLt250k")
                      this.setState({ q8Answer: e.target.innerText })
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    $50,000 - $250,000
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q8Clicked && this.state.annualIncomeMt250k
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("annualIncomeMt250k")
                      this.setState({ q8Answer: e.target.innerText })
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    More than $250,000
                  </div>
                </div>
              </div>

              <div
                className={
                  "wrap" +
                  (this.props.hasError && !this.props.totalNetAssets
                    ? " has-error"
                    : "")
                }
              >
                <p className='qstn'>What are your total net assets?</p>
                <p className='p-sub'>(Not including real estate)</p>

                <div
                  className='flex flex-wrap justify-between mb-3'
                  style={{ marginTop: "20px" }}
                >
                  <div
                    className={
                      "badges " +
                      (this.state.q9Clicked && this.state.totalNetAssetsLt250
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("totalNetAssetsLt250")
                      this.setState({ q9Answer: e.target.innerText })
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    Under $250,000{" "}
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q9Clicked && this.state.totalNetAssetsLt500
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("totalNetAssetsLt500")
                      this.setState({ q9Answer: e.target.innerText })
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    $250,000 - $500,000
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q9Clicked && this.state.totalNetAssetsLt1M
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("totalNetAssetsLt1M")
                      this.setState({ q9Answer: e.target.innerText })
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    $500,000 - $1,000,000
                  </div>
                  <div
                    className={
                      "badges " +
                      (this.state.q9Clicked && this.state.totalNetAssetsLt5M
                        ? "correct"
                        : "")
                    }
                    onClick={e => {
                      this.toggle("totalNetAssetsLt5M")
                      this.setState({ q9Answer: e.target.innerText })
                    }}
                    style={{ marginBottom: "10px" }}
                  >
                    $1m - $5m
                  </div>
                </div>
              </div>

              <div
                className={
                  "checkbox-long " +
                  (this.state.error && !this.state.acceptTerms
                    ? " has-error"
                    : "")
                }
              >
                <label htmlFor=''>
                  <input
                    ref={this.acceptTermsRef}
                    type='checkbox'
                    name='iAccept'
                    onClick={() =>
                      this.setState({ acceptTerms: !this.state.acceptTerms })
                    }
                  />
                  I hereby certify that I meet the criteria of my country of
                  residence to be a qualified investor and opt to be considered
                  as such.
                </label>
              </div>

              <div className='wrap'>
                <p>Note: </p>
                <p>
                  Your approval as an investor on CcHub syndicate does not
                  guarantee that you are a qualified investor according to the
                  laws of your country of residence.
                </p>
                <p>
                  You are solely responsible for complying with applicable laws
                  regarding the determination of a qualified investor and
                  whether an investment opportunity complies with of the terms
                  of the local laws of the country of your residence.
                </p>
              </div>
            </div>
          </div>

          <div className='pagination'>
            <BackButton
              currentPage={this.props.currentPage}
              nextPage={this.props.setPage}
            />
            <div>
              <div
                className={"link "}
                onClick={() => this.submitInvestmentActivities()}
              >
                Next
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}