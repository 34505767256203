import axios from "axios"
import api from "../api"

let { VERIFICATION_API } = api
let baseUrl = VERIFICATION_API

export const updateDropOff = body => {
  axios
    .put(`${baseUrl}/v1/dropOff/update`, body)
    .then(res => {
      localStorage.setItem("pageNo", res.data.pageno)
      return
    })
    .catch(error => {
      console.dir(error)
    })
}
