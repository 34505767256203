import React from "react";
import { Button, Text } from "@chakra-ui/react";

export const PrimaryButton = ({
  label,
  onClick,
  width = "100%",
  outline,
  type,
  disabled,
  isLoading,
}) => {
  return (
    <Button
      type={type}
      background={outline ? "transparent" : "#2D7FF9"}
      borderRadius="0px 10px 10px 20px"
      border="2px solid #2D7FF9"
      width={width}
      height="62px"
      _active=""
      _hover=""
      _focus={{
        outline: "none",
      }}
      isDisabled={disabled}
      onClick={onClick}
      isLoading={isLoading}
    >
      <Text
        color={outline ? "#2D7FF9" : "#FFFFFF"}
        fontSize={18}
        fontWeight={500}
      >
        {label}
      </Text>
    </Button>
  );
};
