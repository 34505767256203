import React, { Component } from 'react';
import axios from 'axios';
import apis from '../../utils/api';
import AddTitleHelmet from '../Helmet';
import BackButton from '../UI/BackBtn';
import FormLoader from "../loader/FormLoader"
import { userEmail } from "../../utils/helpers"
import { updateDropOff } from "../../utils/calls/dropOff.calls"

const API_TOKEN =
  "FDshA17s4G79Ga7wBDOAgAhkTI1mJ9z8U5oGOAg8JGR48tJX6QTDnk8EWQbB9mzKe2s"
const UNIVERSAL_API_EMAIL = "dev@cchubnigeria.com"
const { UNIVERSAL_API, VERIFICATION_API } = apis
const baseUrl = VERIFICATION_API

export default class Identification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countries_list: [],
      selectedCities: [],
      progress: 0,
      uploadedDocuments: [],
      isUploading: false,
      hasError: false,
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      country: "",
      city: "",
      zip_code: "",
      isLoading: false,
      email: '',
      dropOffData: {},
      dropOffLoading: false,
    }
  }

  getCountryCities = country => {
    const config = {
      headers: {
        Accept: "application/json",
        "api-token": API_TOKEN,
        "user-email": UNIVERSAL_API_EMAIL,
      },
    }
    axios
      .get(`${UNIVERSAL_API}/api/getaccesstoken`, config)
      .then(resp => {
        const authToken = resp.data.auth_token

        const authConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
        axios
          .get(`${UNIVERSAL_API}/api/states/${country}`, authConfig)
          .then(({ data }) => {
            this.setState({ selectedCities: data, isLoading: false })
          })
          .catch(error => {
            console.dir(error)
            this.setState({ isLoading: false })
          })
      })
      .catch(error => {
        console.error(error)
        this.setState({ isLoading: false })
      })
  }

  onHandleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onCountryChange = e => {
    this.setState({ country: e.target.value })
    this.getCountryCities(e.target.value)
  }

  setInitialStates = () => {
    if (!this.state.dropOffLoading && this.state.dropOffData.first_name) {
      this.setState({firstName: this.state.dropOffData.first_name})
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.last_name) {
      this.setState({ lastName: this.state.dropOffData.last_name })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.mobile) {
      this.setState({ phone: this.state.dropOffData.mobile })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.address) {
      this.setState({ address: this.state.dropOffData.address })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.country) {
      this.setState({ country: this.state.dropOffData.country })
      this.getCountryCities(this.state.dropOffData.country)
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.city) {
      this.setState({ city: this.state.dropOffData.city })
    }  
    if (!this.state.dropOffLoading && this.state.dropOffData.zipcode) {
      this.setState({ zip_code: this.state.dropOffData.zipcode })
    }  
  }

  componentDidMount() {
    const getDropOff = async () => {
      const email = userEmail()
      this.setState({ dropOffLoading: true })

      await axios
        .get(`${baseUrl}/v1/dropOff/get/${email}`)
        .then(res => {
          this.setState({ dropOffData: res.data, dropOffLoading: false, email: email })
        })
        .catch(error => {
          this.setState({ dropOffLoading: false })
          console.dir(error)
        })

      this.setInitialStates()
    }
    getDropOff()
    this.fetchCountriesList()
  }

  validateAndMoveForward = () => {
    let {
      firstName,
      lastName,
      email,
      phone,
      address,
      country,
      city,
      zip_code,
    } = this.state

    let updatedDropOffData = {
      address: address,
      city: city,
      country: country,
      email: email,
      first_name: firstName,
      last_name: lastName,
      mobile: phone,
      zipcode: zip_code,
      pageno: this.props.currentPage + 1,
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !address ||
      !country ||
      !city ||
      !zip_code
    ) {
      this.setState({ hasError: true })
      return
    }
    updateDropOff(updatedDropOffData)
    this.props.nextPage(this.props.currentPage + 1)
  }

  fetchCountriesList = () => {
    const config = {
      headers: {
        Accept: "application/json",
        "api-token": API_TOKEN,
        "user-email": UNIVERSAL_API_EMAIL,
      },
    }
    axios
      .get(`${UNIVERSAL_API}/api/getaccesstoken`, config)
      .then(resp => {
        const authToken = resp.data.auth_token
        const authConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }

        axios
          .get(`${UNIVERSAL_API}/api/countries`, authConfig)
          .then(({ data }) => {
            this.setState({ countries_list: data })
          })
          .catch(error => {
            console.dir(error.response)
          })
      })
      .catch(error => {
        console.error(error.response)
      })
  }

  render() {
    let { countries_list } = this.state

    let countryOption
    if (countries_list.length > 0) {
      countryOption = countries_list.map((country, index) => (
        <option key={index} value={country.country_name}>
          {country.country_name}
        </option>
      ))
    }

    let { selectedCities } = this.state
    let cityOptions

    if (selectedCities.length > 0) {
      cityOptions = selectedCities.map((city, index) => (
        <option key={index} value={city.state_name}>
          {city.state_name}
        </option>
      ))
    }

    return (
      <React.Fragment>
        <AddTitleHelmet title='Syndicate KYC | Basic Informations' />
        {this.state.dropOffLoading && (
          <FormLoader style={{ backgroundColor: "hsla(0, 50%, 100%, 0.5)" }} />
        )}
        <div className='col-sm-6 col-xs-12 wrapper'>
          <div className='basic-info'>
            <div className='title-header hide-on-mobile'>
              <h1 className='hr hide-on-mobile'>Basic Information</h1>
            </div>

            <form>
              <div className='form-row'>
                <div className='col'>
                  <label htmlFor=''>First name</label>
                  <input
                    type='text'
                    className={
                      "form-control syndicate-control" +
                      (this.state.hasError && !this.state.firstName
                        ? " has-error"
                        : "")
                    }
                    placeholder='First name'
                    name='firstName'
                    value={this.state.firstName}
                    onChange={this.onHandleChange}
                  />
                </div>
                <div className='col'>
                  <label htmlFor=''>Last name</label>
                  <input
                    type='text'
                    className={
                      "form-control syndicate-control" +
                      (this.state.hasError && !this.state.lastName
                        ? " has-error"
                        : "")
                    }
                    placeholder='Last name'
                    name='lastName'
                    value={this.state.lastName}
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>
              <label htmlFor=''>Email address</label>
              <div readOnly className='w-100 form-protected relative'>
                <input
                  type='email'
                  className={
                    "syndicate-control readOnly w-inherit" +
                    (this.state.hasError && !this.state.email
                      ? " has-error"
                      : "")
                  }
                  placeholder='Email address'
                  name='email'
                  value={this.state.email}
                  readOnly
                  onChange={this.onHandleChange}
                />

                <svg
                  width='14'
                  height='16'
                  viewBox='0 0 14 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.4286 5.33333H12.9524C13.1545 5.33333 13.3482 5.41361 13.4911 5.55649C13.634 5.69938 13.7143 5.89317 13.7143 6.09524V15.2381C13.7143 15.4402 13.634 15.634 13.4911 15.7768C13.3482 15.9197 13.1545 16 12.9524 16H0.761905C0.559835 16 0.366042 15.9197 0.223157 15.7768C0.0802718 15.634 0 15.4402 0 15.2381V6.09524C0 5.89317 0.0802718 5.69938 0.223157 5.55649C0.366042 5.41361 0.559835 5.33333 0.761905 5.33333H2.28571V4.57143C2.28571 3.35901 2.76735 2.19625 3.62466 1.33894C4.48196 0.481631 5.64472 0 6.85714 0C8.06956 0 9.23232 0.481631 10.0896 1.33894C10.9469 2.19625 11.4286 3.35901 11.4286 4.57143V5.33333ZM6.09524 11.2244V12.9524H7.61905V11.2244C7.90955 11.0567 8.13659 10.7978 8.26496 10.4879C8.39333 10.178 8.41585 9.83434 8.32903 9.51033C8.24221 9.18632 8.0509 8.90001 7.78478 8.6958C7.51865 8.4916 7.19259 8.38091 6.85714 8.38091C6.5217 8.38091 6.19563 8.4916 5.92951 8.6958C5.66338 8.90001 5.47207 9.18632 5.38526 9.51033C5.29844 9.83434 5.32096 10.178 5.44933 10.4879C5.57769 10.7978 5.80474 11.0567 6.09524 11.2244ZM9.90476 5.33333V4.57143C9.90476 3.76315 9.58367 2.98798 9.01214 2.41644C8.4406 1.8449 7.66542 1.52381 6.85714 1.52381C6.04886 1.52381 5.27369 1.8449 4.70215 2.41644C4.13061 2.98798 3.80952 3.76315 3.80952 4.57143V5.33333H9.90476Z'
                    fill='#9C9C9C'
                  />
                </svg>
              </div>

              <label htmlFor=''>Mobile Phone Number</label>
              <div></div>

              <input
                type='text'
                className={
                  "form-control syndicate-control" +
                  (this.state.hasError && !this.state.phone ? " has-error" : "")
                }
                placeholder='e.g. phone number'
                name='phone'
                value={this.state.phone}
                onChange={this.onHandleChange}
              />

              <div className='title-header'>
                <h1>Address Details</h1>
              </div>

              <label htmlFor=''>Street Address</label>
              <input
                type='text'
                className={
                  "form-control syndicate-control" +
                  (this.state.hasError && !this.state.address
                    ? " has-error"
                    : "")
                }
                placeholder='Street Number, Street Name'
                name='address'
                value={this.state.address}
                onChange={this.onHandleChange}
              />

              <div className='form-row'>
                <div className='col-sm-6'>
                  <label htmlFor=''>Country of Residence</label>

                  <select
                    className={
                      "country form-control syndicate-control" +
                      (this.state.hasError && !this.state.country
                        ? " has-error"
                        : "")
                    }
                    name='country'
                    value={this.state.country}
                    onChange={this.onCountryChange}
                  >
                    <option>Select</option>
                    {countryOption}
                  </select>
                </div>

                <div className='col-sm-6'>
                  <label htmlFor=''>City</label>
                  <select
                    className={
                      "city form-control syndicate-control" +
                      (this.state.hasError && !this.state.city
                        ? " has-error"
                        : "")
                    }
                    onChange={this.onHandleChange}
                    value={this.state.city}
                    name='city'
                  >
                    <option>Select</option>
                    {cityOptions}
                  </select>
                </div>

                <div className='col-sm-6'>
                  <label className='mt20' htmlFor=''>
                    Zipcode
                  </label>
                  <input
                    type='text'
                    className={
                      "form-control syndicate-control" +
                      (this.state.hasError && !this.state.zip_code
                        ? " has-error"
                        : "")
                    }
                    value={this.state.zip_code}
                    placeholder='Zip code'
                    name='zip_code'
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>
            </form>
          </div>

          <div className='pagination'>
            <BackButton
              currentPage={this.props.currentPage}
              nextPage={this.props.nextPage}
            />
            <div>
              <div className='link' onClick={this.validateAndMoveForward}>
                <span>Next</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}