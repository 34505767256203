
var firebaseConfig = {
  apiKey: "AIzaSyAcmP9TISPjZbi3WOv1f8PhZ7tJjEe8rIY",
  authDomain: "syndicate-25006.firebaseapp.com",
  databaseURL: "https://syndicate-25006.firebaseio.com",
  projectId: "syndicate-25006",
  storageBucket: "syndicate-25006.appspot.com",
  messagingSenderId: "112375241329",
  appId: "1:112375241329:web:927decd184c5b480d3d9d0",
  measurementId: "G-SNM3LPTTHH"
};


export default firebaseConfig;