import React from 'react'

const Header = () => (
    <header>
        <div className="container">
            <nav>
                <div className="logo">
                    <img src="/img/syndicate-logo.png" alt="" />
                </div>
            </nav>
        </div>
    </header>
)
export default Header