import React from "react";
import {
  Box,
  Input,
  Link,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react"
import { PrimaryButton } from "../../components/Buttons"
import api from "../../utils/api"
import axios from "axios"
import { useHistory } from "react-router-dom"

let { VERIFICATION_API } = api
let baseUrl = VERIFICATION_API

const SignUp = () => {
  const history = useHistory()
  const toast = useToast()
  const [email, setemail] = React.useState("")
  const [isLoading, setisLoading] = React.useState(false)
  const [authError, setauthError] = React.useState(false)

  const handleSubmission = async () => {
    if (email.length < 2) {
      return
    }
    setauthError(false)
    setisLoading(true)
    axios
      .get(`${baseUrl}/v1/auth/user?email=${email}`)
      .then(result => {
        let { data } = result
        localStorage.setItem("auth", JSON.stringify(data))

        axios
          .get(`${baseUrl}/v1/dropOff/get/${email}`)
          .then(res => {
            localStorage.setItem("pageNo", res.data.pageno)
            history.push("/form")
          })
          .catch(error => {
            history.push("/form")
          })
        setisLoading(false)
      })
      .catch(err => {
        setisLoading(false)
        if (err.response) {
          setauthError(true)
        } else {
          toast({
            title: "An error occured.",
            description: `Check internet connection and try again.`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          })
        }
      })
  }

  return (
    <form
      onSubmit={e => {
        handleSubmission()
        e.preventDefault()
      }}
      height='100%'
    >
      <FormControl isInvalid={authError}>
        <FormLabel
          htmlFor='email'
          color='#2C2C2C'
          fontSize={20}
          fontWeight={400}
          mb={2}
        >
          Email Address
        </FormLabel>
        <Input
          readOnly={isLoading}
          id='email'
          placeholder='example@email.com'
          background='white'
          height='60px'
          position='relative'
          zIndex='1'
          rounded={4}
          onChange={e => {
            setemail(e.target.value)
            setauthError(false)
          }}
        />
        {authError && (
          <FormErrorMessage
            background='#FEEAED'
            padding='30px 20px 20px 20px'
            rounded={4}
            position='relative'
            top='-10px'
          >
            <Box>
              <Text
                color='#CC0022'
                fontWeight='400'
                fontSize='12px'
                marginBottom={3}
              >
                Prospective investors need to be screened ahead of
                accreditation. The email provided above can’t be found on our
                screening list.
              </Text>
              <Link
                href='https://cchubnigeria.com/syndicate'
                color='#CC0022'
                fontWeight='500'
                fontSize='12px'
              >
                <Text textDecoration='underline'>Start screening process</Text>
              </Link>
            </Box>
          </FormErrorMessage>
        )}
      </FormControl>

      <Box maxW='234px' marginTop={10}>
        <PrimaryButton
          label='Verify'
          type='submit'
          disabled={!email || isLoading}
        />
      </Box>
    </form>
  )
}

export default SignUp;
