import React from "react";
import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-BoldItalic.woff2') format('woff2'),
            url('/graphik/Graphik-BoldItalic.woff') format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-ExtralightItalic.woff2') format('woff2'),
            url('/graphik/Graphik-ExtralightItalic.woff') format('woff');
        font-weight: 200;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Extralight.woff2') format('woff2'),
            url('/graphik/Graphik-Extralight.woff') format('woff');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Bold.woff2') format('woff2'),
            url('/graphik/Graphik-Bold.woff') format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Black.woff2') format('woff2'),
            url('/graphik/Graphik-Black.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-BlackItalic.woff2') format('woff2'),
            url('/graphik/Graphik-BlackItalic.woff') format('woff');
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-MediumItalic.woff2') format('woff2'),
            url('/graphik/Graphik-MediumItalic.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Light.woff2') format('woff2'),
            url('/graphik/Graphik-Light.woff') format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-LightItalic.woff2') format('woff2'),
            url('/graphik/Graphik-LightItalic.woff') format('woff');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-RegularItalic.woff2') format('woff2'),
            url('/graphik/Graphik-RegularItalic.woff') format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Medium.woff2') format('woff2'),
            url('/graphik/Graphik-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Regular.woff2') format('woff2'),
            url('/graphik/Graphik-Regular.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik Super';
        src: url('/graphik/Graphik-SuperItalic.woff2') format('woff2'),
            url('/graphik/Graphik-SuperItalic.woff') format('woff');
        font-weight: 900;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-ThinItalic.woff2') format('woff2'),
            url('/graphik/Graphik-ThinItalic.woff') format('woff');
        font-weight: 100;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Thin.woff2') format('woff2'),
            url('/graphik/Graphik-Thin.woff') format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-SemiboldItalic.woff2') format('woff2'),
            url('/graphik/Graphik-SemiboldItalic.woff') format('woff');
        font-weight: 600;
        font-style: italic;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik';
        src: url('/graphik/Graphik-Semibold.woff2') format('woff2'),
            url('/graphik/Graphik-Semibold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'Graphik Super';
        src: url('/graphik/Graphik-Super.woff2') format('woff2'),
            url('/graphik/Graphik-Super.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
      `}
  />
);

export default Fonts;
