import React from "react";
import { Route } from "react-router-dom"
import { Box, Container, Grid, Text, Image } from "@chakra-ui/react";
import syndicate_logo from "../../../assets/img/syndicate-logo.png";
import illustration from "../../../assets/img/investor-illustration.png";

function AuthLayout({ children }) {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        height={["100vh"]}
      >
        {/* sidebar */}
        <Box width={["100%", "100%", "100%", "42%"]} height="100%">
          <Container
            maxW="sm"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Grid placeItems="center" height="10%">
              <Image src={syndicate_logo} width="159px" />
            </Grid>
            <Box>{children}</Box>
            <Box height="15%" />
          </Container>
        </Box>
        <Box
          display={["none", "none", "none", "block"]}
          width={["100%", "100%", "58%"]}
          background="linear-gradient(180deg, #08A3FF 0%, #003777 100%);"
        >
          <Container
            maxW="3xl"
            display="flex"
            flexDir="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box>
              <Image src={illustration} />
            </Box>
            <Box height="23%" width="100%" maxHeight="647px">
              <Text color="white" fontWeight="700" fontSize="40px">
                Get accredited today and start investing in CcHub backed
                startups
              </Text>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

const AuthLayoutRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(props) => {
          return (
            <AuthLayout>
              <Component {...props} />
            </AuthLayout>
          );
      }}
    />
  );
};

export default AuthLayoutRoute;
