import React, { Component } from "react";
import firebase from "firebase";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import DropzoneComponent from "react-dropzone-component"
import apis from "../../utils/api"
import Loader from "../loader/Loader"
import AddTitleHelmet from "../Helmet"
import BackButton from "../UI/BackBtn"
import FormLoader from "../loader/FormLoader"
import { updateDropOff } from "../../utils/calls/dropOff.calls"
import { userEmail } from "../../utils/helpers"

const API_TOKEN =
  "FDshA17s4G79Ga7wBDOAgAhkTI1mJ9z8U5oGOAg8JGR48tJX6QTDnk8EWQbB9mzKe2s"
const UNIVERSAL_API_EMAIL = "dev@cchubnigeria.com"
const { UNIVERSAL_API, VERIFICATION_API } = apis
const baseUrl = VERIFICATION_API

export default class Upload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadedPassport: "",
      isLoading: false,
      file: null,
      countries_list: [],
      dropOffData: [],
      dropOffLoading: false,
      docCountry: "",
    }

    this.djsConfig = {
      addRemoveLinks: false,
      acceptedFiles: "image/jpeg,image/png",
    }

    this.componentConfig = {
      iconFiletypes: [".jpg", ".png", ".jpeg"],
      showFiletypeIcon: true,
      postUrl: "/uploading",
    }

    // Simple callbacks work too, of course
    this.callback = file => {
      this.onDrop(file)
    }

    this.success = file => console.log("uploaded", file)

    this.removedfile = file => console.log("removing...", file)

    this.dropzone = null
  }

  onDrop = acceptedFiles => {
    const name = +new Date() + "-" + acceptedFiles.name
    const metadata = {
      contentType: acceptedFiles.type,
    }

    this.setState({ isLoading: true })
    firebase
      .storage()
      .ref()
      .child(name)
      .put(acceptedFiles, metadata)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(url => {
        this.props.setData({ scanData: url })
        this.setState({
          uploadedPassport: url,
          file: acceptedFiles,
          isLoading: false,
        })
      })
      .catch(error => {
        this.showToast("error", "Error uploading file")
        this.setState({ isLoading: false })
      })
  }

  setInitialStates = () => {
    if (!this.state.dropOffLoading && this.state.dropOffData.uploaded_id) {
      this.setState({
        uploadedPassport: this.state.dropOffData.uploaded_id,
      })
    }
    if (!this.state.dropOffLoading && this.state.dropOffData.nationality) {
      this.setState({
        docCountry: this.state.dropOffData.nationality,
      })
    }
  }

  componentDidMount() {
    this.fetchCountriesList()

    const getDropOff = async () => {
      const email = userEmail()
      this.setState({ dropOffLoading: true })

      await axios
        .get(`${baseUrl}/v1/dropOff/get/${email}`)
        .then(res => {
          this.setState({ dropOffData: res.data, dropOffLoading: false })
        })
        .catch(error => {
          this.setState({ dropOffLoading: false })
          console.dir(error)
        })
      this.setInitialStates()
    }
    getDropOff()
  }

  fetchCountriesList = () => {
    const config = {
      headers: {
        Accept: "application/json",
        "api-token": API_TOKEN,
        "user-email": UNIVERSAL_API_EMAIL,
      },
    }
    axios
      .get(`${UNIVERSAL_API}/api/getaccesstoken`, config)
      .then(resp => {
        const authToken = resp.data.auth_token
        const authConfig = {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }

        axios
          .get(`${UNIVERSAL_API}/api/countries`, authConfig)
          .then(({ data }) => {
            this.setState({ countries_list: data })
          })
          .catch(error => {
            console.dir(error.response)
          })
      })
      .catch(error => {
        console.error(error.response)
      })
  }

  showToast = (status, msg) => {
    if (status === "success") {
      return toast.success(msg, {
        position: "top-center",
        autoClose: 5000,
      })
    }
    return toast.error(msg)
  }

  onHandleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    let { countries_list } = this.state

    let countryOption
    if (countries_list.length > 0) {
      countryOption = countries_list.map((country, index) => (
        <option key={index} value={country.country_short_name}>
          {country.country_name}
        </option>
      ))
    }

    const config = this.componentConfig
    const djsConfig = this.djsConfig

    // For a list of all possible events (there are many), see README.md!
    const eventHandlers = {
      init: dz => (this.dropzone = dz),
      addedfile: this.callback,
      success: this.success,
      removedfile: this.removedfile,
    }

    return (
      <React.Fragment>
        <AddTitleHelmet title='Syndicate KYC | Upload Passport' />
        {this.state.dropOffLoading && (
          <FormLoader style={{ backgroundColor: "hsla(0, 50%, 100%, 0.5)" }} />
        )}
        <div className='col-sm-5 col-xs-12'>
          <div className='basic-info'>
            <div className='title-header'>
              <h1 className='hide-on-mobile'>Upload ID</h1>
              <span>
                Kindly upload your international passport in JPEG format with a
                file size of less than 3MB.{" "}
              </span>
            </div>

            <div
              style={{
                position: "relative",
                height: "180px",
                marginBottom: "5rem",
              }}
            >
              <img
                src={this.state.uploadedPassport}
                alt='passport'
                style={{
                  height: "100%",
                  position: "absolute",
                  width: "100%",
                  top: "0",
                  left: "0",
                  right: "0",
                  objectFit: "cover",
                }}
              />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  opacity: "0.5",
                  fontWeight: "bold",
                }}
              >
                <DropzoneComponent
                  config={config}
                  eventHandlers={eventHandlers}
                  djsConfig={djsConfig}
                />
              </div>
            </div>

            <div className='col-7 mt-5 px-0'>
              <label htmlFor=''>Issuing Country</label>

              <select
                className={"country form-control"}
                name='docCountry'
                value={this.state.docCountry}
                onChange={this.onHandleChange}
              >
                <option>Select</option>
                {countryOption}
              </select>
            </div>
          </div>

          <div className='pagination'>
            <BackButton
              currentPage={this.props.currentPage}
              nextPage={this.props.setPage}
            />
            <div>
              <div
                className={
                  "link " +
                  (this.state.isLoading ? "cursor-blocked" : "cursor-pointer")
                }
                onClick={e => {
                  if (!this.state.uploadedPassport) {
                    alert("Please upload your passport")
                    return
                  }

                  if (!this.state.docCountry) {
                    alert("Please select an issuing country")
                    return
                  }
                  updateDropOff({
                    uploaded_id: this.state.uploadedPassport,
                    nationality: this.state.docCountry,
                    email: userEmail(),
                    pageno: this.props.currentPage + 1,
                  })
                  setTimeout(
                    () => this.props.setPage(this.props.currentPage + 1),
                    800
                  )
                }}
              >
                Upload & Proceed
              </div>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <ToastContainer />
        </div>
      </React.Fragment>
    )
  }
}