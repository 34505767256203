import React from 'react'


const Loader = ({ size = "36px" }) => {
  return (
    <div className="loader-container">
      <div style={{
            width: size,
            display: "flex",
            justifyContent: "center",
            padding: "20%",
            background: "rgba(39, 109, 247, 0.1)",
            borderRadius: "5px"
        }}>
            <i style={{
                fontSize: size,
                color: "#276df7"
            }}
                className="fa fa-spinner fa-pulse fa-fw"></i>
        </div>
    </div>
  )
}

export default Loader
